
import React, {useState, useEffect} from "react";
import {
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Tooltip,
} from "reactstrap";
import { destinationTypes } from "../../../components/constants/destinationTypes";
import ButttonTravelNinjaz from "../../../components/Common/GloablMaster/ButttonTravelNinjaz";
import SearchableSelectOption from "../../../components/Common/GloablMaster/SearchableSelectOption";
import {  
  showDestinationTypeSelectList,
  showDestinationSelectList,
  showCity,
  // showDestinationType ,
  // showDestination,
  //cityList
  // defaultDestinationType ,
  // defaultDestination ,
  // defaultCity 
  // ,defaultCityOption
} 
from "../../../services/common/GlobalVariables";
import CurrencySymbol from "../../../common/CurrencySymbol";
import CitySiteSeeingImages from "./CitySiteSeeingImages";


const CitySiteSeeingPopupModal = ({
  tog_scroll,
  headerName,
  isOpen,
  size,
  scrollable,
  handleAddCitySeeing,
  siteAndRateList,
  onChangeHandller,
  showInvalid,
  readOnly,
  removeSiteHandller,
  is_active,
  modalSaveHandller,
  cancelHandller,
  destinationOptions,
  cityOptions,
  destinationType,
  destination,
  onChange,
  city_id,
  city_name,
  site,
  private_rate,
  sic_rate,

  //File Upload Props
  handleAcceptedFiles,
  selectedFiles,
  removeSelectedImages,
  getImageFileNames,
  onImagePrimaryHandller,
  onDeleteHandller,
}) => {
  // Tooltip Open state
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const currencySymbol = localStorage.getItem("currencySymbol");

   /**This useEffect for calling get api*/
   useEffect(() => {
 
    // if (cityList?.length > 0) {
    //   const activeCityList = cityList.filter(item => item.is_active === true);
    //   activeCityList.map((item, index) => {
    //      cityOptions.push({
    //         label: item.city_name,
    //         value: item.city_id,
    //       });
    //     });
    //   }

}, []);

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={tog_scroll}
        centered
        size={size}
        scrollable={scrollable}
      >
        <ModalHeader className="bg-light p-3">{headerName}</ModalHeader>
        <ModalBody>
          <form>
            <div className="row g-3">

                
            {(showDestinationTypeSelectList == "true" || showDestinationTypeSelectList == true )? (

                  <Col xxl={4}>

                  <SearchableSelectOption
                    labelName={"Destination Type"}
                    options={destinationTypes}
                    value={destinationType}
                    isDisabled={readOnly}
                    onChange={onChange}
                    name={"destinationType"}
                    id={"destinationType"}
                  />
                  </Col>
                    ) :  <>  </>
                    }

               {(showDestinationSelectList == "true" || showDestinationSelectList == true )? (

              <Col xxl={4}>
              <SearchableSelectOption
                labelName={"Destination"}
                options={destinationOptions}
                value={destination}
                isDisabled={readOnly}
                onChange={onChange}
                name={"destination"}
                id={"destination"}
              />
              </Col>
              ) :  <>  </>
              }


             
      {(showCity == "true" || showCity == true )? (   
              <Col xxl={4}>              

                  <label htmlFor="city_name" className="form-label">
                   City Name
                  </label>

                  <Input
                    type="text"
                    className="form-control"
                    name={"city_name"}
                    id={"city_name"}
                    value={city_name}
                    defaultValue={city_name}
                    onChange={(e) => onChangeHandller(e)}
                    invalid={
                      city_name < 1 && showInvalid
                    }
                    readOnly={readOnly}
                    required
                  />

                {!city_name && showInvalid && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "14px",
                      marginLeft: "5px",
                    }}
                  >
                    {" "}
                    Please select City
                  </p>
                )}
           

              </Col>
              ) :  <>  </>
              }



        <Col xxl={12}>
                 <label htmlFor="site" className="form-label">
                   Site 
                    
                  </label>

                  <Input
                    type="textarea"
                    className="form-control"
                    name={"site"}
                    id={"site"}
                    value={site}
                    defaultValue={site}
                    style={{height: 50}}
                    onChange={(e) => onChangeHandller(e)}
                    invalid={
                      !site  && showInvalid
                    }
                    // readOnly={readOnly}
                    required
                  />

                {!site && showInvalid  && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginLeft: "5px",
                    }}
                  >
                    {" "}
                    Site Name is Required
                  </p>
                )}
              </Col>


              {/* <Col xxl={12}>
                <div>
                  <label htmlFor="add_city" className="form-label">
                    Site Name And Rate{" "}
                    <i
                      className="ri-add-line align-bottom mx-2"
                      onClick={handleAddCitySeeing}
                      id="add_city"
                      style={{
                        padding: "3px",
                        // marginTop: "10px",
                        fontSize: "14px",
                        borderRadius: "50%",
                        backgroundColor: "#099885",
                        color: "white",
                        cursor: "pointer",
                      }}
                    ></i>
                    <Tooltip
                      isOpen={tooltipOpen}
                      placement="right"
                      target="add_city"
                      toggle={() => {
                        setTooltipOpen(!tooltipOpen);
                      }}
                    >
                      Add Site and Rate
                    </Tooltip>
                  </label>
                </div>
              </Col> */}
          
              <Col xxl={6}>
              <label htmlFor="private_rate" className="form-label">
                   Private Rate
                  </label>
                   <div className="input-group">
                        <span
                          className="input-group-text"
                          id="basic-addon1"
                        >
                         <CurrencySymbol currencySymbol={currencySymbol} />
                        </span>
                        <Input
                        type="number"
                        className="form-control"
                        name={"private_rate"}
                        id= {"Private_rate"}
                        value={private_rate}
                        defaultValue={private_rate}
                        onChange={(e) => onChangeHandller(e)}
                        invalid={!private_rate && showInvalid}
                        required
                      />
                     
                   </div>
                   {!private_rate && showInvalid ? (
                        <p style={{ fontSize: "12px", color: "red" }}>
                          Private Rate is Required
                        </p>
                      ) : (
                        ""
                      )}
              

              
                </Col>     
                  <Col xxl={6}>
                 <label htmlFor="sic_rate" className="form-label">
                   SIC Rate
                  </label>

                  <div className="input-group">
                        <span
                          className="input-group-text"
                          id="basic-addon1"
                        >
                         <CurrencySymbol currencySymbol={currencySymbol} />
                        </span>
                        <Input
                        type="number"
                        className="form-control"
                        name={"sic_rate"}
                        id= {"sic_rate"}
                        value={sic_rate}
                        defaultValue={sic_rate}
                        onChange={(e) => onChangeHandller(e)}
                        invalid={!sic_rate && showInvalid}
                        required
                      />                   
                   </div>
                    {!sic_rate && showInvalid ? (
                    <p style={{ fontSize: "12px", color: "red" }}>
                    SIC Rate is Required
                    </p>
                    ) : (
                    ""
                    )}
                  
                </Col>  

            </div>
            <div className="form-check form-switch form-switch-success my-3">
              <Input
                className="form-check-input"
                type="checkbox"
                role="switch"
                name="is_active"
                id="SwitchCheck3"
                onChange={(e) => onChangeHandller(e)}
                defaultChecked={is_active}
              />
              <Label className="form-check-label" htmlFor="SwitchCheck3">
                Is Active
              </Label>
            </div>
            <CitySiteSeeingImages
                     //activeArrowTab={activeArrowTab}
                      cancelHandller={cancelHandller}
                      getImageFileNames={getImageFileNames}
                      handleAcceptedFiles={handleAcceptedFiles}
                      modalSaveHandller={modalSaveHandller}
                      onDeleteHandller={onDeleteHandller}
                      onImagePrimaryHandller={onImagePrimaryHandller}
                      removeSelectedImages={removeSelectedImages}
                      selectedFiles={selectedFiles}
                     // toggleArrowTab={toggleArrowTab}
                    />
          </form>
        </ModalBody>
        <div className="modal-footer">
          <ButttonTravelNinjaz
            backGroundColor={"primary"}
            onCLickHancller={modalSaveHandller}
            buttonText={"Save"}
          />
          <ButttonTravelNinjaz
            backGroundColor={"danger"}
            onCLickHancller={cancelHandller}
            buttonText={"Cancel"}
          />
        </div>
      </Modal>
    </>
  );
};

export default CitySiteSeeingPopupModal;
