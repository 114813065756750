import React, { useState } from "react";
import "antd/dist/antd.css";
import Select from "react-select";
import {
  MoreOutlined,
  SearchOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Input,
  Label,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalBody,
} from "reactstrap";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { Table, Space, message } from "antd";
import "../../../assets/scss/components/customantd.scss";
import { useEffect } from "react";
import { useRef } from "react";
import { ReactSession } from "react-client-session";
import { CSVLink } from "react-csv";
import { toast, ToastContainer } from "react-toastify";
import { GetDestinationList } from "../../../services/GlobalMasters/destinationService";
import {
  AddHotelList,
  GetHotelList,
  SaveHotelList,
  UpdateHotelList,
} from "../../../services/GlobalMasters/hotelServices";

// Rating
import Rating from "react-rating";
import { GetCityList } from "../../../services/GlobalMasters/cityServices";
import * as XLSX from "xlsx";
import ButttonTravelNinjaz from "../../../components/Common/GloablMaster/ButttonTravelNinjaz";
import {
  downloadExcel,
  filterUniqueCity,
  filterUniqueDestination,
} from "../../../functions/downloadtoExcel";
import { appName } from "../../../common/applicationName";
import CancelCofirmModal from "../../../common/CancelCofirmModal";
import { getSessionUsingSessionStorage } from "../../../services/common/session";
import {
  errornotify,
  successNotify,
} from "../../../components/Common/notification";
import { authError } from "../../../services/common/const";
import { useNavigate } from "react-router-dom";
import UseAuth from "../../../components/UseAuth";

import {  
  showDestinationType ,
  showDestination,

  destinationTypeList, 
  destinationList ,
  //cityList ,

  defaultDestinationType ,
  defaultDestination ,
  //defaultCity, 

  defaultCityOption
} from "../../../services/common/GlobalVariables";

let form = null;
/*** This is destination functions main */
const Hotel = () => {
  UseAuth();
  // document.title = "Hotel | TravelNinjaz B2B";
  document.title = `${appName}-Hotel`;
  /*This is for cancel/confirmation*/
  const [modal_standard, setmodal_standard] = useState(false);
  const tog_standard = () => {
    setmodal_standard(!modal_standard);
  };
  ReactSession.setStoreType("sessionStorage");
  const [userName, setUserName] = useState();
  const navigate = useNavigate();
  /** get session details*/

  useEffect(() => {
    let promise = getSessionUsingSessionStorage();
    promise
      .then(function (value) {
        return value;
      })
      .then((value) => {
        setUserName(value.userName);
      });
  }, []);
  const [apiHotelData, setApiHotelData] = useState([]);
  const [dataSource, setDataSource] = useState(apiHotelData);
  const [count, setCount] = useState(apiHotelData?.length + 1 || 1);
  const [filterData, setFilterData] = useState([]);
  const [searchTextantd, setSearchTextantd] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [updatable, setUpdatable] = useState(false);
  const searchInput = useRef(null);
  /**This is for hidden/show column*/
  const [rowCreatedBy, setRowCreatedBy] = useState(true);
  const [rowCreatedDate, setRowCreatedDate] = useState(true);
  const [rowModifiedBy, setRowModifiedBy] = useState(true);
  const [rowModifiedDate, setRowModifiedDate] = useState(true);
  const [isSaveNew, setIsSaveNew] = useState(true);
  /**This usestate for show hide and edit*/
  const [showAdd, setShowAdd] = useState(true);
  const [showEdit, setShowEdit] = useState(true);
  const [exportTableData, setExportableData] = useState([]);
  /**Filter destination data*/
  let [filterColunmWiseDestination, setFilterColumnWiseDestination] = useState(
    []
  );
  let [fiterColumnWiseCity, setFilterColunmWiseCity] = useState([]);
  const [apiDestinationData, setApiDestinationData] = useState([]);
  const [apiCityData, setApiCityData] = useState([]);
  const [cityOption, setcityOption] = useState([]);

  const getAllList = async () => {
    try {
      //const destinationResult = await GetDestinationList();
      //const cityResult = await GetCityList();

      debugger;
      // if (cityList != null && cityList.length > 0) {
      //   setApiCityData(cityList);
      // } 
      
      let params = new URLSearchParams();

      let global_default_destination_type_object;
      if(localStorage.getItem("global_default_destination_type_object") != "")
      {
        global_default_destination_type_object = JSON.parse(localStorage.getItem("global_default_destination_type_object") ?? "");
        params.append("destination_type_id",global_default_destination_type_object.destination_type_id);
      }
          
      let global_default_destination_object;
      if(localStorage.getItem("global_default_destination_object") != "")
      { 
        global_default_destination_object = JSON.parse(localStorage.getItem("global_default_destination_object") ?? "");
        params.append("destination_id",global_default_destination_object.destination_id);
      } 

      let global_default_city_object;
      if(localStorage.getItem("global_default_city_object") != "" && localStorage.getItem("global_default_city_object") != "null")
      { 
        global_default_city_object = JSON.parse(localStorage.getItem("global_default_city_object") ?? "");
        params.append("city_id",global_default_city_object.city_id);
      } 
      
      const result = await GetHotelList(params);
      if (result?.data?.status === "SUCCESS") {
        let jsondata = result.data.data;
        setCount(jsondata.length + 1);
        setApiHotelData(jsondata);
        setDataSource(jsondata);
        /**This is for unique destiantion */
        let uniqueDestination = filterUniqueDestination(jsondata);
        let filterDestination = [];
        uniqueDestination?.map((item, index) => {
          filterDestination.push({
            text: item?.destination_name,
            value: item?.destination_id,
          });
        });
        setFilterColumnWiseDestination(filterDestination);
        /**This is for unique city */
        let uniqueCity = filterUniqueCity(jsondata);
        let filterCity = [];
        uniqueCity.map((item, index) => {
          filterCity.push({
            text: item?.city_name,
            value: item?.city_id,
          });
        });
        setFilterColunmWiseCity(filterCity);
      } else {
        throw result.message;
        // return;
      }
      // if (destinationResult?.data?.status === "SUCCESS") {
      //   setApiDestinationData(destinationResult.data.data);
      // } else {
      //   throw destinationResult.message;
      //   // return;
      // }
   

      // if (cityList.length > 0) {
      //   let tempCityOption = [];
      //   cityList
      //     // .filter((item) => {
      //     //   return (
      //     //     item.destination_id === selectedDestinationId && item.is_active
      //     //   );
      //     // })
      //     .map((item, index) => {
      //       tempCityOption.push({
      //         label: item.city_name,
      //         value: item.city_id,
      //       });
      //     });
      //   setcityOption(tempCityOption);
      // }


      // else {
      //   throw cityResult.message;
      //   // return;
      // }
    } catch (error) {
      errornotify(error);
      // if (error === authError) {
      //   navigate("/login");
      // }
    }
  };
  useEffect(() => {
    getAllList();
  }, []);

  /**This is for search columns */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchTextantd(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchTextantd("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: "#233163",
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
              backgroundColor: "#233163",
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchTextantd(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            style={{ backgroundColor: "#233163" }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#233163" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchTextantd]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const [searchText, setSearchText] = useState("");
  const [editablecolumn, setEditableColumn] = useState(false);
  const date = new Date();
  /**This is for edit handller */
  const editHandller = () => {
    setEditableColumn(true);
    setUpdatable(true);
    setShowAdd(false);
  };
  /***This is for save handller */
  const saveHandller = async () => {
    try {
      let newDataSource = dataSource.filter((item) => {
        return !apiHotelData.find((itemB) => {
          return item.key === itemB.key;
        });
      });
      if (updatable) {
        /**This map function for check validation */
        dataSource.map((item, index) => {
          if (item.hotel_name?.length < 1) {
            setIsSaveNew(false);
            throw "Hotel is required.";
          }
          if (!item.city_id) {
            setIsSaveNew(false);
            throw "Please select city.";
          }
        });
        if (isSaveNew) {
          // API code does not accept date so manually removing Date from the source
          newDataSource.map((item, index) => {
            item.row_created_date = new Date(item.row_created_date);
            item.row_altered_date = new Date(item.row_altered_date);
          });
          //UPdate method call
          const result = await UpdateHotelList(dataSource);
          if (result?.status === "SUCCESS") {
            setShowAdd(true);
            setShowEdit(true);
            successNotify(result.message);
            getAllList();
            setUpdatable(false);
            setEditableColumn(false);
          } else {
            errornotify(result.message);
          }
        }
      } else {
        /**This map function for check validation */
        newDataSource.map((item, index) => {
          if (item.hotel_name?.length < 1) {
            setIsSaveNew(false);
            throw "Hotel is required.";
          }
          if (!item.city_id) {
            setIsSaveNew(false);
            throw "Please select city.";
          }
        });
        if (isSaveNew) {
          // API code does not accept date so manually removing Date from the source
          newDataSource.map((item, index) => {
            item.row_created_date = new Date(item.row_created_date);
            item.row_altered_date = new Date(item.row_altered_date);
          });
          let result = await AddHotelList(newDataSource);
          if (result.status === "SUCCESS") {
            setShowAdd(true);
            setShowEdit(true);
            successNotify(result.message);
            getAllList();
            setEditableColumn(false);
          } else {
            errornotify(result.message);
          }
        }
      }
    } catch (error) {
      errornotify(error);
    }
  };
  /**This is for cancel handller */
  const cancelHandller = () => {
    setEditableColumn(false);
    getAllList();
    setUpdatable(false);
    setShowAdd(true);
    setShowEdit(true);
    tog_standard();
    setDataSource(apiHotelData);
  };
  /**This is for input text handller */
  const inputHandller = (searchValue) => {
    setSearchText(searchValue);
    if (searchText !== "") {
      const filterData = dataSource.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
      setFilterData(filterData);
      setExportableData(JSON.parse(JSON.stringify(filterData)));
    } else {
      setFilterData(dataSource);
      setExportableData(JSON.parse(JSON.stringify(dataSource)));
    }
  };
  /** This is for destination type select option handller */
  const [, setDestinationOptions] = useState([]);
  const [, setCityOptions] = useState([]);
  const selectDomesticTypeHandller = (e, record) => {
    record.destination_type_id = parseInt(e.target.value);
    record.destination_id = null;
    record.city_id = null;
    setDestinationOptions([]);
    const newArray = apiDestinationData.filter((item) => {
      return (
        item.destination_type_id === parseInt(e.target.value) && item.is_active
      );
    });
    setDestinationOptions(newArray);
  };
  /** This is for select option handller for destination Name*/
  const selectOptionHandller = (e, record) => {
    record.destination_id = parseInt(e.target.value);
    record.city_id = null;
    const newArray = apiCityData.filter((item) => {
      return item.destination_id === parseInt(e.target.value) && item.is_active;
    });
    setCityOptions(newArray);
  };
  /**destinationTypes type */
  const destinationTypes = [
    {
      text: "Domestic",
      value: 1,
    },
    {
      text: "International",
      value: 2,
    },
  ];
  /**This is for select option handller for city name*/
  const [citySelect, setCitySelect] = useState(1);
  const selectOptionCityHandller = (e, record) => {
    record.city_id = parseInt(e.target.value);
    if (record.city_id) {
      setIsSaveNew(true);
    }
    setCitySelect(e.target.value);
  };
  const defaultColumns = [
    {
      title: "Destination Type",
      width: 190,
      dataIndex: "destination_type_id",
      filters: destinationTypes,
      defaultSortOrder: "ascend",
      // filterMode: "tree",
      onFilter: (value, record) => record.destination_type_id === value,
      render: (text, record) => {
        return (
          <div>
            <select
              className="form-select "
              aria-label="Default select example"
              onChange={(e) => selectDomesticTypeHandller(e, record)}
              disabled={!editablecolumn && !record?.isEditing}
            >
              <option>Select</option>
              {destinationTypes.map((item, index) => {
                return (
                  <option
                    value={item.value}
                    key={index}
                    selected={record.destination_type_id == item.value}
                  >
                    {item.text}
                  </option>
                );
              })}
            </select>
          </div>
        );
      },
      sorter: {
        compare: (a, b) => a.destination_type_id - b.destination_type_id,
        multiple: 10,
      },
      hidden: (showDestinationType == true || showDestinationType == "true" ? false : true ),
    },
    {
      title: "Destination",
      width: 200,
      dataIndex: "destination_id",
      filters: filterColunmWiseDestination,
      defaultSortOrder: "ascend",
      // filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.destination_id === value,
      render: (text, record) => {
        return (
          <div>
            <select
              className="form-select "
              aria-label="Default select example"
              onChange={(e) => selectOptionHandller(e, record)}
              disabled={!editablecolumn && !record?.isEditing}
            >
              <option>Select</option>
              {apiDestinationData
                .filter((item) => {
                  return (
                    item.is_active &&
                    record.destination_type_id === item.destination_type_id
                  );
                })
                ?.map((item, index) => {
                  return (
                    <option
                      value={item.destination_id}
                      key={index}
                      selected={record.destination_id === item.destination_id}
                    >
                      {item?.destination_name}
                    </option>
                  );
                })}
            </select>
          </div>
        );
      },
      sorter: {
        compare: (a, b) => a.destination_name.localeCompare(b.destination_name),
        multiple: 9,
      },
      //hidden: !showDestination,
      hidden: (showDestination == true || showDestination == "true" ? false : true ),
    },
    {
      title: "City",
      width: 200,
      dataIndex: "city_id",
      filters: fiterColumnWiseCity,
      defaultSortOrder: "ascend",
      // filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.city_id === value,
      render: (text, record) => {
        return (

           

          <div>
            {/* <Select
                    value={selectedCity}
                    id="city"
                    isDisabled={readOnly}
                    onChange={(chooseOption) => {
                      selectCityHandller(chooseOption);
                    }}
                    
                    className={
                      !selectedCity && showInvalid ? "border border-danger" : ""
                    }
                    options={cityOption}
                    components={animatedComponents}
                  /> */}
           
            <select
              className={
                !record.city_id && !isSaveNew
                  ? "form-select border-danger"
                  : "form-select"
              }
              aria-label="Default select example"
              onChange={(e) => selectOptionCityHandller(e, record)}
              disabled={!editablecolumn && !record?.isEditing}
            >
              <option>Select</option>
              {apiCityData
                .filter((item) => {
                  return (
                  //  item.destination_id === record.destination_id &&
                    item.is_active
                  );
                })
                ?.map((item, index) => {
                  return (
                    <option
                      value={item.city_id}
                      key={index}
                      selected={record.city_id === item.city_id}
                    >
                      {item?.city_name}
                    </option>
                  );
                })}
            </select>
            {!record.city_id && !isSaveNew && (
              <p style={{ fontSize: "12px", color: "red" }}>
                Please select city.
              </p>
            )}
          </div>
        );
      },
      sorter: {
        //compare: (a, b) => a.city_name.localeCompare(b.city_name),
        multiple: 8,
      },
    },
    {
      title: "Hotel",
      dataIndex: "hotel_name",
      width: 280,
      defaultSortOrder: "ascend",
      sorter: {
        compare: (a, b) => a.hotel_name.localeCompare(b.hotel_name),
        multiple: 7,
      },
      // sorter: (a, b) => a.hotel_name.localeCompare(b.hotel_name),
      ...getColumnSearchProps("hotel_name"),
      render: (text, record) => {
        const inputHandller = (e) => {
          record.hotel_name = e.target.value;
          if (record.hotel_name.length > 0) {
            setIsSaveNew(true);
          }
        };
        return !editablecolumn && !record?.isEditing ? (
          <Input
            type="text"
            className="form-control"
            disabled={!editablecolumn && !record?.isEditing}
            value={record.hotel_name}
          />
        ) : (
          <>
            <Input
              type="text"
              className="form-control"
              defaultValue={record?.hotel_name}
              onChange={(e) => inputHandller(e)}
              invalid={record?.hotel_name.length < 1 && !isSaveNew}
              required
            />
            {record?.hotel_name.length < 1 && !isSaveNew && (
              <p style={{ fontSize: "12px", color: "red" }}>
                Hotel Name is Required
              </p>
            )}
          </>
        );
      },
    },
    {
      title: "Hotel Type",
      dataIndex: "hotel_type",
      width: 160,
      render: (text, record) => {
        return (
          <div dir="ltr">
            <div id="rater-onhover" className="align-middle d-inline">
              <Rating
                style={{ fontSize: "22px" }}
                stop={5}
                emptySymbol="mdi mdi-star-outline text-muted "
                fullSymbol="mdi mdi-star text-warning "
                initialRating={record.hotel_type || 0}
                onChange={(customize) => {
                  record.hotel_type = customize;
                }}
                readonly={!editablecolumn && !record?.isEditing}
              />
            </div>
          </div>
        );
      },
      sorter: {
        compare: (a, b) => a.hotel_type - b.hotel_type,
        multiple: 6,
      },
      // sorter: (a, b) => a.hotel_type - b.hotel_type,
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      width: 130,
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
      // filterMode: "tree",
      onFilter: (value, record) => record.is_active === value,
      render: (text, record) => {
        return (
          <div className="form-check form-switch form-switch-success">
            <Input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="SwitchCheck3"
              onChange={(e) => (record.is_active = e.target.checked)}
              defaultChecked={record.is_active}
              disabled={!editablecolumn && !record?.isEditing}
            />
          </div>
        );
      },
      sorter: {
        compare: (a, b) => a.is_active - b.is_active,
        multiple: 5,
      },
    },
    {
      title: "Created By",
      dataIndex: "row_created_by",
      width: 250,
      sorter: {
        compare: (a, b) => a.row_created_by.localeCompare(b.row_created_by),
        multiple: 4,
      },
      hidden: rowCreatedBy,
    },
    {
      title: "Created On",
      width: 160,
      dataIndex: "row_created_date",
      sorter: {
        compare: (a, b) => a.row_created_date.localeCompare(b.row_created_date),
        multiple: 3,
      },
      hidden: rowCreatedDate,
      render: (text, record) => {
        const date = new Date(record.row_created_date);
        return (
          <>
            {date.getDate()}/
            {date.toLocaleString("default", { month: "short" })}/
            {date.getFullYear()}
            {` ${date.getHours()}:${date.getMinutes()}`}
          </>
        );
      },
    },
    {
      title: "Modified By",
      width: 250,
      dataIndex: "row_altered_by",
      sorter: {
        compare: (a, b) => a.row_altered_by.localeCompare(b.row_altered_by),
        multiple: 2,
      },
      hidden: rowModifiedBy,
    },
    {
      title: "Modified On",
      width: 160,
      dataIndex: "row_altered_date",
      sorter: {
        compare: (a, b) => a.row_altered_date.localeCompare(b.row_altered_date),
        multiple: 1,
      },
      hidden: rowModifiedDate,
      render: (text, record) => {
        const date = new Date(record.row_altered_date);
        return (
          <>
            {date.getDate()}/
            {date.toLocaleString("default", { month: "short" })}/
            {date.getFullYear()}
            {` ${date.getHours()}:${date.getMinutes()}`}
          </>
        );
      },
    },
  ].filter((item) => !item.hidden);

  /**This is for new add */
  const AddHandler = () => {
    let addData = [...dataSource];
    const newData = {
      key: count,
      //city_id: defaultCity?.city_id,
      city_id: null,
      hotel_id: count,
      hotel_name: "",
      hotel_type: 0,
      is_active: true,
      row_created_by: userName,
      row_created_date: `${date.getDate()}/${
        date.getMonth() + 1
      }/${date.getFullYear()}`,
      row_altered_by: userName,
      row_altered_date: `${date.getDate()}/${
        date.getMonth() + 1
      }/${date.getFullYear()}`,
      isEditing: true,
    };
    addData.splice(0, 0, newData);

    setDataSource(addData);
    setShowEdit(false);
    setCount(count + 1);
    setUpdatable(false);
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  const onChange = (pagination, filters, sorter, extra) => {};
  useEffect(() => {
    setExportableData(JSON.parse(JSON.stringify(dataSource)));
  }, [, rowCreatedBy, rowCreatedDate, rowModifiedBy, rowModifiedDate]);
  useEffect(() => {
    if (searchText === "") {
      setExportableData(JSON.parse(JSON.stringify(dataSource)));
    }
  }, [searchText]);
  const manageColumnExcel = () => {
    exportTableData.map((item, index) => {
      delete item["key"];
      delete item["operation"];
      delete item["city_id"];
      delete item["hotel_id"];
      delete item["destination_id"];
      delete item["destination_type_id"];
      delete item["isEditing"];
      delete item["hotelMealPlanList"];
      {
        rowCreatedBy && delete item["row_created_by"];
      }
      {
        rowCreatedDate && delete item["row_created_date"];
      }
      {
        rowModifiedBy && delete item["row_altered_by"];
      }
      {
        rowModifiedDate && delete item["row_altered_date"];
      }
    });
  };
  useEffect(() => {
    if (exportTableData.length > 0) {
      manageColumnExcel();
    }
  }, [exportTableData]);
  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Hotel" pageTitle="Masters" isSubTitle={true} />
          <Row>
            <Col lg={12}>
              <Card>
                {/* <CardHeader>
                  <h4 className="card-title mb-0 flex-grow-1">
                    Hotel Add ,Edit
                  </h4>
                </CardHeader> */}
                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div>
                          {showAdd && (
                            <ButttonTravelNinjaz
                              backGroundColor="success"
                              className="add-btn me-1 my-1"
                              id="create-btn"
                              onCLickHancller={AddHandler}
                              buttonIcon={
                                <i className="ri-add-line align-bottom me-1"></i>
                              }
                              buttonText="Add"
                            />
                          )}
                          {!editablecolumn && showEdit ? (
                            <ButttonTravelNinjaz
                              backGroundColor="info"
                              className="add-btn me-1 my-1"
                              id="edit-btn"
                              onCLickHancller={editHandller}
                              buttonIcon={
                                <i className="ri-edit-line align-bottom me-1"></i>
                              }
                              buttonText="Edit"
                            />
                          ) : (
                            <>
                              <ButttonTravelNinjaz
                                backGroundColor="primary"
                                className="add-btn me-1 my-1"
                                id="save-btn"
                                onCLickHancller={saveHandller}
                                buttonText="Save"
                              />
                              <ButttonTravelNinjaz
                                backGroundColor="danger"
                                className="add-btn me-1 mx-1 my-1"
                                id="cancel-btn"
                                onCLickHancller={tog_standard}
                                buttonText="Cancel"
                              />
                            </>
                          )}
                          <UncontrolledDropdown
                            style={{ display: "inline-block" }}
                            className="my-1"
                          >
                            <DropdownToggle
                              tag="button"
                              className="btn btn-secondary"
                              type="button"
                            >
                              Export
                            </DropdownToggle>
                            <DropdownMenu>
                              <CSVLink
                                filename={"TravelNinjazB2BDestination.csv"}
                                data={exportTableData}
                                className="btn btn-secondary m-1 mx-2"
                                onClick={() => {
                                  message.success("The file is downloading");
                                }}
                              >
                                Export to CSV
                              </CSVLink>
                              <ButttonTravelNinjaz
                                backGroundColor="secondary"
                                className="add-btn m-1 mx-1"
                                id="cancel-btn"
                                onCLickHancller={() =>
                                  downloadExcel(exportTableData, "HotelTable")
                                }
                                buttonText="Export to Excel"
                              />
                            </DropdownMenu>
                          </UncontrolledDropdown>
                          <UncontrolledDropdown
                            style={{
                              display: "inline-block",
                            }}
                          >
                            <DropdownToggle
                              tag="button"
                              className="btn btn-light mx-1"
                              type="button"
                            >
                              <MoreOutlined style={{ fontSize: "21px" }} />
                            </DropdownToggle>
                            <DropdownMenu>
                              <div className="form-check form-switch form-switch-success mb-3">
                                <Input
                                  className="form-check-input mx-1"
                                  type="checkbox"
                                  role="switch"
                                  id="SwitchCheck1"
                                  defaultChecked={!rowCreatedBy}
                                  onChange={(e) =>
                                    setRowCreatedBy(!e.target.checked)
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="SwitchCheck1"
                                >
                                  Created By
                                </Label>
                              </div>
                              <div className="form-check form-switch form-switch-success mb-3">
                                <Input
                                  className="form-check-input mx-1"
                                  type="checkbox"
                                  role="switch"
                                  id="SwitchCheck2"
                                  defaultChecked={!rowCreatedDate}
                                  onChange={(e) =>
                                    setRowCreatedDate(!e.target.checked)
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="SwitchCheck2"
                                >
                                  Created On
                                </Label>
                              </div>
                              <div className="form-check form-switch form-switch-success mb-3">
                                <Input
                                  className="form-check-input mx-1"
                                  type="checkbox"
                                  role="switch"
                                  id="SwitchCheck3"
                                  defaultChecked={!rowModifiedBy}
                                  onChange={(e) =>
                                    setRowModifiedBy(!e.target.checked)
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="SwitchCheck3"
                                >
                                  Modified By
                                </Label>
                              </div>
                              <div className="form-check form-switch form-switch-success mb-3">
                                <Input
                                  className="form-check-input mx-1"
                                  type="checkbox"
                                  role="switch"
                                  id="SwitchCheck4"
                                  defaultChecked={!rowModifiedDate}
                                  onChange={(e) =>
                                    setRowModifiedDate(!e.target.checked)
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="SwitchCheck4"
                                >
                                  Modified On
                                </Label>
                              </div>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </Col>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end my-1">
                          <div className="search-box ms-2 ">
                            <input
                              type="text"
                              className="form-control search"
                              placeholder="Search..."
                              onChange={(e) => inputHandller(e.target.value)}
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {/* {!editablecolumn ? (
                      <Row className="g-4 mb-3 d-xl-none d-sm-none d-md-none">
                        <Col className="col-sm">
                          <Button
                            color="primary"
                            className="add-btn me-1"
                            onClick={saveHandller}
                            id="create-btn"
                          >
                            Save
                          </Button>
                          <Button
                            color="primary"
                            className="add-btn me-1 "
                            onClick={cancelHandller}
                            id="create-btn"
                          >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )} */}
                  </div>
                  <div>
                    {searchText.length > 0 ? (
                      <Table
                        // components={components}
                        rowClassName={() => "editable-row"}
                        bordered
                        dataSource={filterData}
                        columns={columns}
                        scroll={{
                          y: 320,
                        }}
                        pagination={{
                          defaultPageSize: 10,
                          showSizeChanger: true,
                          pageSizeOptions: [10, 20, 50, 100],
                          showTotal: (total, range) =>
                            `${range[0]}-${range[1]} of ${total} items  `,
                        }}
                        onChange={onChange}
                        locale={{
                          triggerDesc: null,
                          triggerAsc: null,
                          cancelSort: null,
                        }}
                      />
                    ) : (
                      <>
                        <Table
                          // components={components}
                          rowClassName={() => "editable-row"}
                          bordered
                          dataSource={dataSource}
                          columns={columns}
                          scroll={{
                            y: 320,
                          }}
                          pagination={{
                            defaultPageSize: 10,
                            showSizeChanger: true,
                            pageSizeOptions: [10, 20, 50, 100],
                            showTotal: (total, range) =>
                              `${range[0]}-${range[1]} of ${total} items  `,
                          }}
                          onChange={onChange}
                          locale={{
                            triggerDesc: null,
                            triggerAsc: null,
                            cancelSort: null,
                          }}
                        />
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <CancelCofirmModal
        cancelHandller={cancelHandller}
        modal_standard={modal_standard}
        tog_standard={tog_standard}
      />
      {/* <Modal
        id="myModal"
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
        centered
        style={{ width: "280px" }}
      >
        <ModalBody>
          <div className="warningContainer">
            <WarningOutlined className="warningStyle" />
            <p className="warningPara">Are you sure to Cancel?</p>
          </div>
        </ModalBody>
        <div className="modal-footer">
          <ButttonTravelNinjaz
            backGroundColor="danger"
            onCLickHancller={cancelHandller}
            buttonText="Yes"
          />
          <ButttonTravelNinjaz
            backGroundColor="light"
            onCLickHancller={() => {
              tog_standard();
            }}
            buttonText="NO"
          />
        </div>
      </Modal> */}
    </>
  );
};

export default Hotel;
