import React from "react";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import {Alert,Button,Card,CardBody,Col,Container,DropdownMenu,DropdownToggle,Input,Label,Modal,ModalBody,ModalHeader,Row,UncontrolledDropdown} from "reactstrap";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useEffect } from "react";
import { GetDestinationList } from "../../../services/GlobalMasters/destinationService";
import { GetCityList } from "../../../services/GlobalMasters/cityServices";
import { Table, Space, message } from "antd";
import { useRef } from "react";
import { MoreOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import ButttonTravelNinjaz from "../../../components/Common/GloablMaster/ButttonTravelNinjaz";
import {GetTransportRateList,SaveTransportRateList} from "../../../services/GlobalMasters/transportRateService";
import { GetVehicleList } from "../../../services/GlobalMasters/vehicleService";
import {downloadExcel,filterUniqueCity,filterUniqueDestination} from "../../../functions/downloadtoExcel";
import { appName } from "../../../common/applicationName";
import { getSessionUsingSessionStorage } from "../../../services/common/session";
import CurrencyShow from "../../../common/CurrencyShow";
import CurrencySymbol from "../../../common/CurrencySymbol";

import {errornotify,successNotify} from "../../../components/Common/notification";
import { authError } from "../../../services/common/const";
import { useNavigate } from "react-router-dom";
import UseAuth from "../../../components/UseAuth";
import {  
  showDestinationType ,
  showDestination,
  // destinationTypeList, 
  // destinationList ,
  // cityList ,
  // defaultDestinationType ,
  // defaultDestination ,
  // defaultCity 
  // ,defaultCityOption
} 
from "../../../services/common/GlobalVariables";
import X from "feather-icons-react/build/IconComponents/X";

const animatedComponents = makeAnimated();

const TransportRate = () => {

  UseAuth();
  // document.title = "Transport Rate | TravelNinjaz B2B";
  document.title = `${appName}-Transport Rate`;
  const [transportRateList, setTransportRateList] = useState([]);
  /**This is for show Table formate*/
  const [vehicleList, setVehicleList] = useState([]);
  /**This is for save purpose */
  const [saveVehicleList, setSaveVehicleList] = useState([]);
  const [showInvalid, setShowInvalid] = useState(false);
  const [userName, setUserName] = useState();
  const navigate = useNavigate();
  const currencySymbol = localStorage.getItem("currencySymbol");
  /** get session details*/
  
 const [showDestinationTypeSelectList, setShowDestinationTypeSelectList] = useState(localStorage.getItem("global_show_destination_type") =="true" ? true: false);
 const [showDestinationSelectList, setShowDestinationSelectList] = useState(localStorage.getItem("global_show_destination") =="true" ? true: false);

 const [destinationTypeList, setDestinationTypeList] = useState(JSON.parse(localStorage.getItem("global_destinations_type_list")));
 const [destinationList, setDestinationList] = useState( JSON.parse(localStorage.getItem("global_destinations_list")));
 const [cityList, setCityList] =  useState( JSON.parse(localStorage.getItem("global_city_list")));
 
const [defaultDestinationType, setDefaultDestinationType] = useState(JSON.parse(localStorage.getItem("global_default_destination_type_object")));
const [defaultDestination, setDefaultDestination] = useState( JSON.parse(localStorage.getItem("global_default_destination_object")));
const [defaultCity, setDefaultCity] =  useState(localStorage.getItem("global_default_city_object") == "" ? {}: JSON.parse(localStorage.getItem("global_default_city_object")));



  useEffect(() => {
    let promise = getSessionUsingSessionStorage();
    promise
      .then(function (value) {
        return value;
      })
      .then((value) => {
        setUserName(value.userName);
      });
  }, []);
  const dates = new Date();
  /**This is for search columns */
  const searchInput = useRef(null);
  const [searchTextantd, setSearchTextantd] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [filterColumnWiseDestination, setFilterColumnWiseDestination] =
    useState([]);
  const [filterColumnWiseCity, setFilterColumnWiseCity] = useState([]);
  /**This is for hidden/show column*/
  const [rowCreatedBy, setRowCreatedBy] = useState(true);
  const [rowCreatedDate, setRowCreatedDate] = useState(true);
  const [rowModifiedBy, setRowModifiedBy] = useState(true);
  const [rowModifiedDate, setRowModifiedDate] = useState(true);
  /**This is for over all search */
  const [searchText, setSearchText] = useState("");
  const [filterData, setFilterData] = useState([]);
  /**destination selected */
  const [selectedDestinationType, setselectedDestinationType] = useState(null);
  const [selectedDestinationTypeId, setselectedDestinationTypeId] =
    useState(null);
  /**destination name selected*/
  const [selectedDestination, setselectedDestination] = useState(null);
  const [selectedDestinationId, setselectedDestinationId] = useState(defaultDestination.destination_id);
  /**city name selected */
  const [selectedCity, setselectedCity] = useState(null);
  const [selectedCityId, setselectedCityId] = useState(null);

  /**This is for destination,city list*/
  // const [destinationList, setDestinationList] = useState([]);
  // const [cityList, setCityList] = useState([]);

  /**This is option for destination*/
  const [destinationOption, setDestinationOption] = useState([]);
  const [exportTableData, setExportableData] = useState([]);

  const [readOnly, setReadOnly] = useState(false);
  useEffect(() => {
    let tempDestinationList = [];
    if (destinationList.length > 0 && selectedDestinationTypeId) {
      const filterDestinationList = destinationList.filter((item) => {
        return (
          item.destination_type_id === selectedDestinationTypeId &&
          item.is_active
        );
      });
      filterDestinationList.map((item, index) => {
        tempDestinationList.push({
          label: item.destination_name,
          value: item.destination_id,
        });
      });
      setDestinationOption(tempDestinationList);
    }
  }, [destinationList, selectedDestinationTypeId]);

  const [modal_scroll, setmodal_scroll] = useState(false);
  function tog_scroll() {
    setmodal_scroll(!modal_scroll);
  }
  /**This is option for city */
  const [cityOption, setcityOption] = useState([]);
  useEffect(() => {
    // if (cityList.length > 0 && selectedDestinationId) {
    //   let tempCityOption = [];
    //   cityList
    //     .filter((item) => {
    //       return (
    //         item.destination_id === selectedDestinationId && item.is_active
    //       );
    //     })
    //     .map((item, index) => {
    //       tempCityOption.push({
    //         label: item.city_name,
    //         value: item.city_id,
    //       });
    //     });
    //   setcityOption(tempCityOption);
    // }
    if (cityList.length > 0) {
      let tempCityOption = [];
      cityList
        // .filter((item) => {
        //   return (
        //     item.destination_id === selectedDestinationId && item.is_active
        //   );
        // })
        .map((item, index) => {
          tempCityOption.push({
            label: item.city_name,
            value: item.city_id,
          });
        });
      setcityOption(tempCityOption);
    }

  }, [cityList, selectedDestinationId]);

  /**This is function for select destination type handllder */
  const selectDestinationTypeHandller = (selectdestinationType) => {
    setDestinationOption([]);
    setselectedDestinationType(selectdestinationType);
    setselectedDestinationTypeId(selectdestinationType.value);
    setselectedDestination(null);
    setselectedDestinationId(null);
    setselectedCity(null);
    setselectedCityId(null);
  };
  /**This is function for destination handller*/
  const selectDestinationHandller = (selectdestination) => {
    setcityOption([]);
    setselectedDestination(selectdestination);
    setselectedDestinationId(selectdestination.value);
    setselectedCity(null);
    setselectedCityId(null);
  };

  /***This is function for city handller*/
  const selectCityHandller = (selectcity) => {
    debugger;
    setselectedCity(selectcity);
    setselectedCityId(selectcity.value);
  };

  /**This function for get list city and destination */
  const getAllList = async () => {
    try {
      // const destinationResult = await GetDestinationList();
      // if (destinationResult?.data?.status === "SUCCESS") {
      //   setDestinationList(destinationResult.data.data);
      // } else {
      //   throw destinationResult.message;
      //   // return;
      // }
      // const cityResult = await GetCityList();
      // if (cityResult?.data?.status === "SUCCESS") {
      //   setCityList(cityResult.data.data);
      // } else {
      //   throw cityResult.message;
      //   // return;
      // }

      debugger;

      const vehicleResult = await GetVehicleList();
      if (vehicleResult?.data?.status === "SUCCESS") {
        const newArray = vehicleResult.data.data
          .filter((item) => {
            return item.is_active;
          })
          .map((item, index) => {
            return { ...item, is_active: false };
          });
        setVehicleList(newArray);
      } else {
        throw vehicleResult.message;
        // return;
      }

      let params = new URLSearchParams();

      let global_default_destination_type_object;
      if(localStorage.getItem("global_default_destination_type_object") != null && localStorage.getItem("global_default_destination_type_object") != "")
      {
        global_default_destination_type_object = JSON.parse(localStorage.getItem("global_default_destination_type_object") ?? "");
        params.append("destination_type_id",global_default_destination_type_object.destination_type_id);
      }
          
      let global_default_destination_object;
      if(localStorage.getItem("global_default_destination_object") != null && localStorage.getItem("global_default_destination_object") != "")
      { 
        global_default_destination_object = JSON.parse(localStorage.getItem("global_default_destination_object") ?? "");
        params.append("destination_id",global_default_destination_object.destination_id);
      } 

      let global_default_city_object;
      if(localStorage.getItem("global_default_city_object") != null && localStorage.getItem("global_default_city_object") != "")
      { 
        global_default_city_object = JSON.parse(localStorage.getItem("global_default_city_object") ?? "");
        params.append("city_id",global_default_city_object.city_id);
      } 

      
      const transportRateResult = await GetTransportRateList(params);
      if (transportRateResult?.data?.status === "SUCCESS") {
        setTransportRateList(transportRateResult.data.data);
        /**This is for destination filter  */
        let uniqueDestination = filterUniqueDestination(
          transportRateResult.data.data
        );
        let filterDestination = [];
        uniqueDestination.map((item, index) => {
          filterDestination.push({
            text: item?.destination_name,
            value: item?.destination_id,
          });
        });
        setFilterColumnWiseDestination(filterDestination);
        /**This is for unique city */
        let uniqueCity = filterUniqueCity(transportRateResult.data.data);
        let filterCity = [];
        uniqueCity.map((item, index) => {
          filterCity.push({
            text: item?.city_name,
            value: item?.city_id,
          });
        });
        setFilterColumnWiseCity(filterCity);
      } else {
        throw transportRateResult.message;
        // return;
      }
    } catch (error) {
      errornotify(error);
      // if (error === authError) {
      //   navigate("/login");
      // }
    }
  };

  /**This useEffect  for get list*/
  useEffect(() => {
    getAllList();
  }, []);

  /**This is for children data*/
  const expandedRowRender = (row) => {
    const columns = [
      {
        title: "Vehicle",
        dataIndex: "vehicle_name",
      },
      {
        title: "Rate",
        dataIndex: "vehicle_price",
        render: (text, record) => {
          return (
            <CurrencyShow
              currency={record?.vehicle_price}
              currencySymbol={currencySymbol}
            />
          );
        },
      },
    ];
    return (
      <Table
        columns={columns}
        dataSource={row.transportRateList}
        pagination={false}
        style={{marginTop: 20}}
      />
    );
  };

  /**This is for destination types for select option*/
  const destinationTypes = [
    {
      label: "Domestic",
      value: 1,
    },
    {
      label: "International",
      value: 2,
    },
  ];

  /**This destination types for filter*/
  const destinationTypesFilter = [
    {
      text: "Domestic",
      value: 1,
    },
    {
      text: "International",
      value: 2,
    },
  ];
  const [inputList, setInputList] = useState({
    is_active: true,
    transport_rate_name: "",
    transport_rate_id: 0,
  });

  /**input change handller*/
  const onChangeHandller = (e) => {
    if (e.target.name === "is_active") {
      setInputList({ ...inputList, [e.target.name]: e.target.checked });
    } else {
      setShowInvalid(false);
      setInputList({
        ...inputList,
        [e.target.name]: e.target.value.toString(),
      });
      const newArray = vehicleList.map((item, i) => {
        if (parseInt(e.target.id) === item.transport_id) {
          return {
            ...item,
            [e.target.name]: e.target.value.toString(),
          };
        } else {
          return item;
        }
      });
      setVehicleList(newArray);
      const newArraySaveVehicle = saveVehicleList.map((item, i) => {
        if (parseInt(e.target.id) === item.transport_id) {
          return { ...item, [e.target.name]: e.target.value.toString() };
        } else {
          return item;
        }
      });
      setSaveVehicleList(newArraySaveVehicle);
    }
  };

  /**check box handller*/
  const checkBoxHandller = (e, key) => {
    const newArray = vehicleList.map((item, i) => {
      if (key === item.transport_id) {
        return {
          ...item,
          is_active: e.target.checked,
          vehicle_price: null,
        };
      } else {
        return item;
      }
    });
    setVehicleList(newArray);
    if (e.target.checked) {
      saveVehicleList.push({
        vehicle_name: e.target.value,
        vehicle_price: null,
        transport_id: key,
      });
    } else {
      const index = saveVehicleList.findIndex((object) => {
        return object.transport_id === key;
      });
      if (index > -1) {
        setShowInvalid(false);
        saveVehicleList.splice(index, 1);
      }
    }
  };
  /**Modal save handller */
  const modalSaveHandller = async () => {
    try {
      setShowInvalid(true);
      debugger;
      if (inputList.transport_rate_name.length < 1) {
        throw "Transport Name is required.";
      }
      if (!selectedCityId) {
        throw "Please select City";
      }
      saveVehicleList.map((item, index) => {
        if (!item.vehicle_price) {
          throw "Transport Rate is required.";
        }
      });
      if (!selectedCityId) {
        throw "Please select City";
      }
      if (saveVehicleList.length > 0) {
        const updatableData = {
          City_id: selectedCityId,
          Transport_rate_id: inputList.transport_rate_id,
          Transport_rate_name: inputList.transport_rate_name,
          TransportRateList: saveVehicleList,
          Is_active: inputList.is_active,
          Row_created_by: userName,
          Row_created_date: new Date(),
          Row_altered_by: userName,
          Row_altered_date: new Date(),
        };
        const response = await SaveTransportRateList(updatableData);
        if (response?.status === "SUCCESS") {
          await getAllList();
          successNotify(response?.message);
          tog_scroll();
          setShowInvalid(false);
          setSaveVehicleList([]);
          setInputList({ ...inputList, transport_rate_name: "" });
          setReadOnly(false);
        } else {
          errornotify(response?.message);
        }
      }
    } catch (error) {
      errornotify(error);
    }
  };
  /**For column search */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchTextantd(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchTextantd("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: "#233163",
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
              backgroundColor: "#233163",
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchTextantd(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            style={{ backgroundColor: "#233163" }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#233163" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchTextantd]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  /***Edit handller*/
  const editHandller = (record) => {
    console.log(record);
    setReadOnly(true);
    {
      record.destination_type_id === 1
        ? setselectedDestinationType({ label: "Domestic", value: 1 })
        : setselectedDestinationType({ label: "International", value: 2 });
    }
    setselectedDestination({
      label: record.destination_name,
      value: record.destination_id,
    });
    setselectedCity({
      label: record.city_name,
      value: record.city_id,
    });
    setselectedCityId(record.city_id);
    setInputList({
      ...inputList,
      is_active: record.is_active,
      transport_rate_name: record.transport_rate_name,
      transport_rate_id: record.transport_rate_id,
    });
    vehicleList.map((item, index) => {
      record.transportRateList.map((itemRecord, indexRecord) => {
        if (item.vehicle_name === itemRecord.vehicle_name) {
          vehicleList[index] = record.transportRateList[indexRecord];
        }
      });
    });
    record.transportRateList.map((item, index) => {
      saveVehicleList.push({
        vehicle_name: item.vehicle_name,
        vehicle_price: item.vehicle_price,
        transport_id: item.transport_id,
      });
    });
    tog_scroll();
  };
  /**This is for add button handller*/
  const AddHandler = () => {
    setInputList({
      ...inputList,
      transport_rate_id: 0,
      transport_rate_name: "",
    });
    setSaveVehicleList([]);
    setReadOnly(false);
    // debugger;
    // var temp = JSON.parse(localStorage.getItem("global_search_city_option"));
    // setselectedCity(temp);
    // setselectedDestinationType();
    // setselectedDestination();
    // setselectedDestinationTypeId(null);
    // setselectedCity();

    const global_search_destination_type_object = defaultDestinationType;
    
    if(global_search_destination_type_object != null && global_search_destination_type_object != 0 )
    {
      setselectedDestinationType(global_search_destination_type_object.value);
      setselectedDestinationTypeId(global_search_destination_type_object.value);

    }
     else{
      setselectedDestinationType();
      setselectedDestinationTypeId(null);
     }

    // const global_search_destination = JSON.parse(localStorage.getItem(SELECT_GLOBAL_SEARCH_DESTINATION_VALUE_KEY) ?? "[]");
    const global_search_destination = defaultDestination;

    if(global_search_destination != null && global_search_destination != 0 )
      setselectedDestination(global_search_destination.value);
    else{
      setselectedDestination();
    }
    //const global_search_city = JSON.parse(localStorage.getItem(SELECT_GLOBAL_SEARCH_CITY_VALUE_KEY) ?? "[]");
   // const global_search_city = defaultCityOption;

   const global_search_city = null;


    if(global_search_city != null && global_search_city != 0 )
      setselectedCity(global_search_city);
   else 
      setselectedCity();

    tog_scroll();
  };

  /**This is for cancel handller */
  const cancelHandller = () => {
    setReadOnly(false);
    setShowInvalid(false);
    setmodal_scroll(false);
    setInputList({ ...inputList, transport_rate_name: "" });
    /**This for mounting call*/
    getAllList();
  };
  /**Table and column*/
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 80,
      render: (text, record) => {
        return (
          <button
            type="button"
            className="btn btn-sm btn-info"
            onClick={() => editHandller(record)}
          >
            {" "}
            Edit{" "}
          </button>
        );
      },
    },
    {
      title: "Destination Type",
      dataIndex: "destination_type_id",
      width: 190,
      filters: destinationTypesFilter,
      // filterMode: "tree",
      onFilter: (value, record) => record.destination_type_id === value,
      render: (text, record) => {
        return record.destination_type_id === 1 ? "Domestic" : "International";
      },
      sorter: {
        compare: (a, b) => a.destination_type_id - b.destination_type_id,
        multiple: 10,
      },
      defaultSortOrder: "ascend",
      hidden: (showDestinationType == true || showDestinationType == "true" ? false : true ),
    },
    {
      title: "Destination",
      dataIndex: "destination_name",
      defaultSortOrder: "ascend",
      width: 140,
      // ...getColumnSearchProps("destination_name"),
      filters: filterColumnWiseDestination,
      // filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.destination_id === value,
      sorter: {
        compare: (a, b) => a.destination_name.localeCompare(b.destination_name),
        multiple: 9,
      },
      hidden: (showDestination == true || showDestination == "true" ? false : true ),
    },
    {
      title: "City",
      dataIndex: "city_name",
      width: 140,
      defaultSortOrder: "ascend",
      // ...getColumnSearchProps("city_name"),
      filters: filterColumnWiseCity,
      // filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.city_id === value,
      sorter: {
        compare: (a, b) => a.city_name.localeCompare(b.city_name),
        multiple: 8,
      },
    },
    {
      title: "Transport Name",
      ...getColumnSearchProps("transport_rate_name"),
      dataIndex: "transport_rate_name",
      width: 250,
      defaultSortOrder: "ascend",
      sorter: {
        compare: (a, b) =>
          a.transport_rate_name.localeCompare(b.transport_rate_name),
        multiple: 7,
      },
      // sorter: (a, b) =>
      //   a.transport_rate_name.localeCompare(b.transport_rate_name),
      render: (text, record) => {
        return <>{record.transport_rate_name}</>;
      },
    },
    {
      title: "Starting Price",
      width: 150,
      dataIndex: "transport_starting_price",
      defaultSortOrder: "ascend",
      sorter: {
        compare: (a, b) =>
          a.transport_starting_price - b.transport_starting_price,
        multiple: 6,
      },
      render: (text, record) => {
        return (
          <>
            <CurrencyShow
              currency={record?.transport_starting_price}
              currencySymbol={currencySymbol}
            />
            {/* {record?.transport_starting_price?.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            })} */}
          </>
        );
      },
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      width: 130,
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
      // filterMode: "tree",
      onFilter: (value, record) => record.is_active === value,
      sorter: {
        compare: (a, b) => a.is_active - b.is_active,
        multiple: 5,
      },
      render: (text, record) => {
        return (
          <div className="form-check form-switch form-switch-success">
            <Input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="SwitchCheck3"
              onChange={(e) => (record.is_active = e.target.checked)}
              defaultChecked={record.is_active}
              disabled
            />
          </div>
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "row_created_by",
      width: 250,
      sorter: {
        compare: (a, b) => a.row_created_by.localeCompare(b.row_created_by),
        multiple: 4,
      },
      hidden: rowCreatedBy,
    },
    {
      title: "Created On",
      width: 160,
      dataIndex: "row_created_date",
      sorter: {
        compare: (a, b) => a.row_created_date.localeCompare(b.row_created_date),
        multiple: 3,
      },
      hidden: rowCreatedDate,
      render: (text, record) => {
        const date = new Date(record.row_created_date);
        return (
          <>
            {date.getDate()}/
            {date.toLocaleString("default", { month: "short" })}/
            {date.getFullYear()}
            {` ${date.getHours()}:${date.getMinutes()}`}
          </>
        );
      },
    },
    {
      title: "Modified By",
      dataIndex: "row_altered_by",
      width: 250,
      sorter: {
        compare: (a, b) => a.row_altered_by.localeCompare(b.row_altered_by),
        multiple: 2,
      },
      hidden: rowModifiedBy,
    },
    {
      title: "Modified On",
      dataIndex: "row_altered_date",
      width: 160,
      sorter: {
        compare: (a, b) => a.row_altered_date.localeCompare(b.row_altered_date),
        multiple: 1,
      },
      hidden: rowModifiedDate,
      render: (text, record) => {
        const date = new Date(record.row_altered_date);
        return (
          <>
            {date.getDate()}/
            {date.toLocaleString("default", { month: "short" })}/
            {date.getFullYear()}
            {` ${date.getHours()}:${date.getMinutes()}`}
          </>
        );
      },
    },
  ].filter((item) => !item.hidden);
  /**This is overAll serch handller */
  const inputHandller = (searchValue) => {
    setSearchText(searchValue);
    if (searchText !== "") {
      const filterData = transportRateList.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
      setFilterData(filterData);
      setExportableData(JSON.parse(JSON.stringify(filterData)));
    } else {
      setFilterData(transportRateList);
      setExportableData(JSON.parse(JSON.stringify(transportRateList)));
    }
  };
  /**This is for export table data to excel*/
  useEffect(() => {
    setExportableData(JSON.parse(JSON.stringify(transportRateList)));
  }, [
    transportRateList,
    rowCreatedBy,
    rowCreatedDate,
    rowModifiedBy,
    rowModifiedDate,
  ]);
  useEffect(() => {
    if (searchText === "") {
      setExportableData(JSON.parse(JSON.stringify(transportRateList)));
    }
  }, [searchText]);

  const [exportedData, setExportedData] = useState([]);

  let exportArray = [];
  /** Export data*/
  useEffect(() => {
    setExportedData([]);
    if (exportTableData.length > 0) {
      exportTableData?.forEach((elementParent) => {
        elementParent?.transportRateList?.forEach((element) =>
          exportArray.push({
            destination_type:
              elementParent.destination_type_id === 1
                ? "Domestic"
                : "International",
            destination_name: elementParent.destination_name,
            city_name: elementParent.city_name,
            transport_rate_name: elementParent.transport_rate_name,
            transport_starting_price: elementParent.transport_starting_price,
            vehicle_name: element.vehicle_name,
            vehicle_price: element.vehicle_price,
            ...(!rowCreatedBy && {
              row_created_by: elementParent.row_created_by,
            }),
            ...(!rowCreatedDate && {
              row_created_date: elementParent.row_created_date,
            }),
            ...(!rowModifiedBy && {
              row_altered_by: elementParent.row_altered_by,
            }),
            ...(!rowModifiedDate && {
              row_altered_date: elementParent.row_altered_date,
            }),
          })
        );
      });
      setExportedData(exportArray);
    }
  }, [exportTableData, filterData]);


  const navigateImportTransportRate = () => {
    navigate('../ImportTransportRate', {replace: true});
  };

  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Transport Rate"
            pageTitle="Setup"
            isSubTitle={true}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div>
                          <ButttonTravelNinjaz
                            backGroundColor="success"
                            className="add-btn me-1 my-1"
                            id="create-btn"
                            onCLickHancller={AddHandler}
                            buttonIcon={
                              <i className="ri-add-line align-bottom me-1"></i>
                            }
                            buttonText="Add"
                          />
                          <UncontrolledDropdown
                            style={{ display: "inline-block" }}
                            className="my-1"
                          >
                            <DropdownToggle
                              tag="button"
                              className="btn btn-secondary"
                              type="button"
                            >
                              Export
                            </DropdownToggle>
                            <DropdownMenu>
                              <CSVLink
                                filename={"TravelNinjazB2BDestination.csv"}
                                data={exportedData}
                                className="btn btn-secondary m-1 mx-2"
                                onClick={() => {
                                  message.success("The file is downloading");
                                }}
                              >
                                Export to CSV
                              </CSVLink>
                              <ButttonTravelNinjaz
                                backGroundColor="secondary"
                                className="add-btn m-1 mx-1"
                                id="cancel-btn"
                                onCLickHancller={() =>
                                  downloadExcel(
                                    exportedData,
                                    "TransportRateTable"
                                  )
                                }
                                buttonText="Export to Excel"
                              />
                            </DropdownMenu>
                          </UncontrolledDropdown>

                          <ButttonTravelNinjaz
                            backGroundColor="primary"
                            className="btn btn-secondary me-1 mx-1 my-1"
                            id="cancel-btn"
                            onCLickHancller={() =>
                              navigateImportTransportRate()
                            }                            
                            buttonText="Import"                            
                          />


                          <UncontrolledDropdown
                            style={{
                              display: "inline-block",
                            }}
                            className="my-1"
                          >
                            <DropdownToggle
                              tag="button"
                              className="btn btn-light mx-1"
                              type="button"
                            >
                              <MoreOutlined style={{ fontSize: "21px" }} />
                            </DropdownToggle>
                            <DropdownMenu>
                              <div className="form-check form-switch form-switch-success mb-3">
                                <Input
                                  className="form-check-input mx-1"
                                  type="checkbox"
                                  role="switch"
                                  id="SwitchCheck1"
                                  onChange={(e) =>
                                    setRowCreatedBy(!e.target.checked)
                                  }
                                  defaultChecked={!rowCreatedBy}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="SwitchCheck1"
                                >
                                  Created By
                                </Label>
                              </div>
                              <div className="form-check form-switch form-switch-success mb-3">
                                <Input
                                  className="form-check-input mx-1"
                                  type="checkbox"
                                  role="switch"
                                  id="SwitchCheck2"
                                  onChange={(e) =>
                                    setRowCreatedDate(!e.target.checked)
                                  }
                                  defaultChecked={!rowCreatedDate}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="SwitchCheck2"
                                >
                                  Created On
                                </Label>
                              </div>
                              <div className="form-check form-switch form-switch-success mb-3">
                                <Input
                                  className="form-check-input mx-1"
                                  type="checkbox"
                                  role="switch"
                                  defaultChecked={!rowModifiedBy}
                                  id="SwitchCheck3"
                                  onChange={(e) =>
                                    setRowModifiedBy(!e.target.checked)
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="SwitchCheck3"
                                >
                                  Modified By
                                </Label>
                              </div>
                              <div className="form-check form-switch form-switch-success mb-3">
                                <Input
                                  className="form-check-input mx-1"
                                  type="checkbox"
                                  role="switch"
                                  id="SwitchCheck4"
                                  onChange={(e) =>
                                    setRowModifiedDate(!e.target.checked)
                                  }
                                  defaultChecked={!rowModifiedDate}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="SwitchCheck4"
                                >
                                  Modified On
                                </Label>
                              </div>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </Col>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end my-1">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              className="form-control search"
                              placeholder="Search..."
                              onChange={(e) => inputHandller(e.target.value)}
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    {searchText.length > 0 ? (
                      <Table
                        dataSource={filterData}
                        columns={columns}
                        scroll={{
                          y: 320,
                        }}
                        pagination={{
                          defaultPageSize: 10,
                          showSizeChanger: true,
                          pageSizeOptions: [10, 20, 50, 100],
                          showTotal: (total, range) =>
                            `${range[0]}-${range[1]} of ${total} items  `,
                        }}
                        expandedRowRender={expandedRowRender}
                        bordered
                        locale={{
                          triggerDesc: null,
                          triggerAsc: null,
                          cancelSort: null,
                        }}
                      />
                    ) : (
                      <Table
                        dataSource={transportRateList}
                        expandedRowRender={expandedRowRender}
                        columns={columns}
                        scroll={{
                          y: 320,
                        }}
                        pagination={{
                          defaultPageSize: 10,
                          showSizeChanger: true,
                          pageSizeOptions: [10, 20, 50, 100],
                          showTotal: (total, range) =>
                            `${range[0]}-${range[1]} of ${total} items  `,
                        }}
                        bordered
                        locale={{
                          triggerDesc: null,
                          triggerAsc: null,
                          cancelSort: null,
                        }}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Add Modal */}
      <Modal
        isOpen={modal_scroll}
        toggle={() => {
          tog_scroll();
        }}
        centered
        size="lg"
        scrollable={true}
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            tog_scroll();
          }}
        >
          {!readOnly ? "Add" : "Edit"} Transport Rate
        </ModalHeader>
        <ModalBody>
          <form>
            <div className="row g-3">

            {(showDestinationType == "true" || showDestinationType == true )? (
  <Col xxl={6}>
  <div>
    <label htmlFor="destinationType" className="form-label">
      Destination Type
    </label>
    <Select
      value={selectedDestinationType}
      id="destinationType"
      onChange={(chooseOption) => {
        selectDestinationTypeHandller(chooseOption);
      }}
      options={destinationTypes}
      components={animatedComponents}
      isDisabled={readOnly}
    />

  </div>
</Col>
   ) :  <>  </>
  }
  
            
        {(showDestination == "true" || showDestination == true )? (
        <Col xxl={6}>
        <div>
          <label htmlFor="destination" className="form-label">
            Destination
          </label>
          <Select
            value={selectedDestination}
            id="destination"
            onChange={(chooseOption) => {
              selectDestinationHandller(chooseOption);
            }}
            options={destinationOption}
            components={animatedComponents}
            isDisabled={readOnly}
          />
        </div>
      </Col>
        ) :  <>  </>
        }
        

             
              <Col xxl={6}>
                <div>
                  {" "}
                  <label htmlFor="city" className="form-label">
                    City
                  </label>
                  <Select
                    value={selectedCity}
                    id="city"
                    // isDisabled={readOnly}
                    onChange={(chooseOption) => {
                      selectCityHandller(chooseOption);
                    }}
                    className={
                      !selectedCity && showInvalid ? "border border-danger" : ""
                    }
                    options={cityOption}
                    components={animatedComponents}
                  />
                  {!selectedCity && showInvalid && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "5px",
                      }}
                    >
                      {" "}
                      Please select City
                    </p>
                  )}
                </div>
              </Col>
              <Col xxl={6}>
                <div>
                  <label htmlFor="transport_rate_name" className="form-label">
                    Transport Rate Name
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    name="transport_rate_name"
                    value={inputList.transport_rate_name}
                    defaultValue={inputList.transport_rate_name}
                    onChange={(e) => onChangeHandller(e)}
                    invalid={
                      inputList.transport_rate_name.length < 1 && showInvalid
                    }
                    // readOnly={readOnly}
                    required
                  />
                  {inputList.transport_rate_name.length < 1 && showInvalid ? (
                    <p style={{ fontSize: "12px", color: "red" }}>
                      Transport Name is Required
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              <div>
                <div className="table-responsive table-card mt-3 mb-1">
                  <table
                    className="table align-middle table-nowrap"
                    id="customerTable"
                  >
                    <thead className="table-light">
                      <tr>
                        <th className="">Available</th>
                        <th className="">Vehicle</th>
                        <th className="">Rate</th>
                      </tr>
                    </thead>
                    <tbody>
                      {vehicleList.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Input
                                type="checkbox"
                                value={item.vehicle_name}
                                name={`checkBox${item.vehicle_name}`}
                                onChange={(e) =>
                                  checkBoxHandller(e, item.transport_id)
                                }
                                defaultChecked={item.is_active}
                              ></Input>
                            </td>
                            <td className="vehicle_name" width={300}>
                              {item.vehicle_name}
                            </td>
                            <td className="price" width={280}>
                              <div className="input-group">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                       <CurrencySymbol currencySymbol={currencySymbol} />
                                </span>
                                <Input
                                  type="number"
                                  name="vehicle_price"
                                  id={item.transport_id}
                                  value={
                                    !item.is_active ? "" : item.vehicle_price
                                  }
                                  readOnly={!item.is_active}
                                  onChange={(e) => onChangeHandller(e)}
                                  min={0}
                                  invalid={
                                    item.is_active &&
                                    !item.vehicle_price &&
                                    showInvalid
                                  }
                                ></Input>
                              </div>
                              {item.is_active &&
                                !item.vehicle_price &&
                                showInvalid && (
                                  <p style={{ color: "red" }}>
                                    Vehicle Rate is required
                                  </p>
                                )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="form-check form-switch form-switch-success my-3">
              <Input
                className="form-check-input"
                type="checkbox"
                role="switch"
                name="is_active"
                id="SwitchCheck3"
                onChange={(e) => onChangeHandller(e)}
                defaultChecked={inputList.is_active}
              />
              <Label className="form-check-label" htmlFor="SwitchCheck3">
                Is Active
              </Label>
            </div>
          </form>
        </ModalBody>
        <div className="modal-footer">
          {saveVehicleList.length < 1 && showInvalid && (
            <>
              {" "}
              <Alert
                color="danger"
                className="alert-border-left mb-xl-0"
                style={{ marginRight: "auto" }}
              >
                <i className="ri-error-warning-line me-3 align-middle fs-16"></i>
                Please select Atleast One Vehicle.
              </Alert>
            </>
          )}
          <ButttonTravelNinjaz
            backGroundColor={"primary"}
            onCLickHancller={modalSaveHandller}
            buttonText={"Save"}
          />
          <ButttonTravelNinjaz
            backGroundColor={"danger"}
            onCLickHancller={cancelHandller}
            buttonText={"Cancel"}
          />
        </div>
      </Modal>
    </>
  );
};

export default TransportRate;
