import React, { useState } from "react";
import "antd/dist/antd.css";
import { CSVLink } from "react-csv";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Input,
  Modal,
  Label,
  ModalBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { Image, message, Space, Table } from "antd";
import "../../../assets/scss/components/customantd.scss";
import { useEffect } from "react";
import { useRef } from "react";
import { ReactSession } from "react-client-session";
import {
  MoreOutlined,
  SearchOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import * as XLSX from "xlsx";
import {
  GetDestinationList,
  AddDestinationList,
  UpdateDestinationList,
  SaveImageDestination,
  saveIamgeDestination,
} from "../../../services/GlobalMasters/destinationService";
import ButttonTravelNinjaz from "../../../components/Common/GloablMaster/ButttonTravelNinjaz";
import ConfirmationWarning from "../../../components/Common/GloablMaster/ConfirmationWarning";
import { downloadExcel } from "../../../functions/downloadtoExcel";
import { authError, url } from "../../../services/common/const";
import { appName } from "../../../common/applicationName";
import CancelCofirmModal from "../../../common/CancelCofirmModal";
import { getSessionUsingSessionStorage, getSession } from "../../../services/common/session";
import {
  errornotify,
  successNotify,
} from "../../../components/Common/notification";
import { useNavigate } from "react-router-dom";
import UseAuth from "../../../components/UseAuth";

import {  SELECT_GLOBAL_SEARCH_DESTINATION_TYPE_VALUE_KEY ,SELECT_GLOBAL_SEARCH_DESTINATION_VALUE_KEY ,SELECT_GLOBAL_SEARCH_CITY_VALUE_KEY  } from "../../../services/common/const";
import LoadingButton from "../../../common/LoadingButton";

import { DragOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import dragula from "dragula";
import "dragula/dist/dragula.css";
import "../../../assets/DraggableTable.css";
import { string } from "prop-types";

//const SELECT_GLOBAL_SEARCH_DESTINATION_VALUE_KEY = "global_search_destination";

/*** This is destination functions main */
const Destination = () => {
  // document.title = "Destination | TravelNinjaz B2B";
  UseAuth();
  document.title = `${appName}-Destination`;
  /*This is for cancel/confirmation*/
  const [modal_standard, setmodal_standard] = useState(false);
  const tog_standard = () => {
    setmodal_standard(!modal_standard);
  };

  //Destination List is for Destination <Select> options
  const [destinationList, setDestinationList] = useState([]);
  const navigate = useNavigate();

  //Data Source is used to Bind Data table
  const [dataSource, setDataSource] = useState(destinationList);
  ReactSession.setStoreType("sessionStorage");
  const [userName, setUserName] = useState();
  const [count, setCount] = useState(destinationList?.length + 1 || 1);

  const [updatable, setUpdatable] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const searchInput = useRef(null);
  const [editablecolumn, setEditableColumn] = useState(false);
  const [sortBy] = useState(1);
  const [isActive] = useState(true);
  const [exportTableData, setExportableData] = useState([]);
  const [imageFormData, setImageFormData] = useState([]);
  const [defaultDestinationType, setDefaultDestinationType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const date = new Date();

  
  /**This function for get destination list from database */
  const getDestinationFunc = async () => {
    try {

      // let params = new URLSearchParams();
      // params.append("destination_type_id",2);
       
      // let global_default_destination_type_object;
      // if(localStorage.getItem("global_default_destination_type_object") != "")
      // {
      //   global_default_destination_type_object = JSON.parse(localStorage.getItem("global_default_destination_type_object") ?? "");
      //   params.append("destination_type_id",global_default_destination_type_object.destination_type_id);
      // }

      //   let global_default_destination_object;
      //   if(localStorage.getItem("global_default_destination_object") != "")
      //   { 
      //     global_default_destination_object = JSON.parse(localStorage.getItem("global_default_destination_object") ?? "");
      //     params.append("destination_id",global_default_destination_object.destination_id);
      //   } 


      // let promise = getSession("global_search_destination_value");
      // promise
      // .then(function (value) {
      //   return value;
      // }) 
      // .then((value) => {
      //   const global_search_destination_value = parseInt(value);

      //   if(global_search_destination_value !== undefined && global_search_destination_value != 0 )
      //   params.append("destination_id",global_search_destination_value);

      // });
  
      
      let params = new URLSearchParams();
     
      let destinationType = JSON.parse(localStorage.getItem("global_search_destination_type_option"));
      let destination =  JSON.parse(localStorage.getItem("global_search_destination_option"));

      if(destinationType != null)
        params.append("destination_type_id", destinationType?.value);

      if(destination != null)
        params.append("destination_id", destination?.value);


      const result = await GetDestinationList(params);
      if (result?.data?.status === "SUCCESS") {
        let jsondata = result.data.data;
        setDestinationList(jsondata);
        setDataSource(jsondata);
        setCount(jsondata?.length + 1);
      } else {
        throw result?.message;
      }
    } catch (error) {
      errornotify(error);
      // if (error === authError) {
      //   navigate("/login");
      // }
    }
  };
  useEffect(() => {
    getDestinationFunc();
  }, []);
  /** get session details*/

  useEffect(() => {
    let promise = getSessionUsingSessionStorage();
    promise
      .then(function (value) {
        return value;
      })
      .then((value) => {
        setUserName(value.userName);
      });
  }, []);

  /**This is for search columns */
  const [searchTextantd, setSearchTextantd] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  /**This is for hidden/show column*/
  const [showDestinationType, setShowDestinationType] = useState(false);
  const [rowCreatedBy, setRowCreatedBy] = useState(true);
  const [rowCreatedDate, setRowCreatedDate] = useState(true);
  const [rowModifiedBy, setRowModifiedBy] = useState(true);
  const [rowModifiedDate, setRowModifiedDate] = useState(true);

  /**This usestate for show hide and edit*/
  const [showAdd, setShowAdd] = useState(true);
  const [showEdit, setShowEdit] = useState(true);

  /**This function for column search */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchTextantd(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  /**This is for reset filter*/
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchTextantd("");
  };

  /**This function for result column search */
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: "#233163",
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
              backgroundColor: "#233163",
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchTextantd(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            style={{ backgroundColor: "#233163" }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#233163" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchTextantd]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  /**This is for edit cell for edit handller */
  const editHandller = () => {
    setEditableColumn(true);
    setUpdatable(true);
    setShowAdd(false);
  };
  const [isRequired, setIsRequired] = useState(true);

  /***This is for Add/Update handller */
  const saveHandller = async () => {

    debugger;

    if (imageFormData.length > 0) {
      try {
        imageFormData.map(async (item) => {
          const formData = new FormData();
          formData.append("file", item, item.name);
          let result = await saveIamgeDestination(formData);
          if (result?.status === "SUCCESS") {
            item.destination_image = result?.data;
            setIsRequired(true);
          } else {
            errornotify(result?.message);
          }
        });
      } catch (error) {
        errornotify(error);
        return;
      }
    }
    setImageFormData([]);
    try {
      let newDataSource = dataSource.filter((item) => {
        return !destinationList.find((itemB) => {
          return item.key === itemB.key;
        });
      });
      if (updatable) {
        /**This map function for check validation */
        dataSource.map((item, index) => {
          if (item.destination_name?.length < 1) {
            setIsRequired(false);
            throw "Name is required.";
          }
          if (item.destination_title?.length < 1) {
            setIsRequired(false);
            throw "Title is required.";
          }
          if (item.destination_description?.length < 1) {
            setIsRequired(false);
            throw "Description is required.";
          }
          if (item.destination_image?.length < 1) {
            setIsRequired(false);
            throw "Image is required.";
          }
        });
        if (isRequired) {
          // API code does not accept date so manually removing Date from the source
          newDataSource.map((item, index) => {
            //item.row_created_date = new Date(item.row_created_date);
            item.row_altered_date = new Date(item.row_altered_date);
          });

          //UPdate method call
          const result = await UpdateDestinationList(dataSource);
          if (result?.status === "SUCCESS") {
            successNotify(result.message);
            getDestinationFunc();
            setUpdatable(false);
            setEditableColumn(false);
            setShowAdd(true);
            setShowEdit(true);
          } else {
            errornotify(result?.message);
          }
        }
      } else {
        /**This map function for check validation */
        newDataSource.map((item, index) => {
          if (item.destination_name?.length < 1) {
            setIsRequired(false);
            throw "Destination is required.";
          }

          if (item.destination_title?.length < 1) {
            setIsRequired(false);
            throw "Title is required.";
          }

          if (item.destination_description?.length < 1) {
            setIsRequired(false);
            throw "Description is required.";
          }
          if (item.destination_image?.length < 1) {
            setIsRequired(false);
            throw "Image is required.";
          }
        });

        if (isRequired) {
          // API code does not accept date so manually removing Date from the source
          newDataSource.map((item, index) => {
            item.row_created_date = new Date(item.row_created_date);
            item.row_altered_date = new Date(item.row_altered_date);
            item.package_count = 0;
          });

          //Add method call
          
         let result = await AddDestinationList(newDataSource);
          if (result?.status === "SUCCESS") {
            successNotify(result.message);
            getDestinationFunc();
            setShowAdd(true);
            setShowEdit(true);
            setEditableColumn(false);
          } else {
            errornotify(result?.message);
          }
        }
      }
    } catch (error) {
      errornotify(error);
    }
  };
  /**This is for cancel handller */
  const cancelHandller = () => {
    setEditableColumn(false);
    setDataSource(destinationList);
    setImageFormData([]);
    setUpdatable(false);
    setShowAdd(true);
    setShowEdit(true);
    tog_standard();
  };
  /**This is for input text handller */
  const inputHandller = (searchValue) => {
    setSearchText(searchValue);
    if (searchText !== "") {
      const filterData = dataSource.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
      setFilterData(filterData);
      setExportableData(JSON.parse(JSON.stringify(filterData)));
    } else {
      setFilterData(dataSource);
      setExportableData(JSON.parse(JSON.stringify(dataSource)));
    }
  };

  /** This is for select option handller */
  const selectiOptionHandller = (e, record) => {
    
    record.destination_type_id = parseInt(e.target.value);
  };

  /**destinationTypes type */
  const destinationTypes = [
    {
      text: "Domestic",
      value: 1,
    },
    {
      text: "International",
      value: 2,
    },
  ];

  const tableRef = useRef(null);

  /**column*/
  const defaultColumns = [
    {
      title: "Destination Type",
      dataIndex: "destination_type_id",
      width: 100,
      filters: [
        {
          text: "Domestic",
          value: 1,
        },
        {
          text: "International",
          value: 2,
        },
      ],
      // filterMode: "tree",
      onFilter: (value, record) => record.destination_type_id === value,
      render: (text, record) => {
        return (
          <div>
            <select
              className="form-select "
              aria-label="Default select example"
              onChange={(e) => selectiOptionHandller(e, record)}
              disabled={!editablecolumn && !record?.isEditing}
            >
              <option    
                    value={0}
                    key={"--Select--"}>--Select--</option>
              {destinationTypes.map((item, index) => {
                return (
                  <option
                    value={item.value}
                    key={index}                                                                                                                                                 
                    selected={record.destination_type_id === item.value}
                  >
                    {item.text}
                  </option>
                );
              })}
            </select>

            {(record?.destination_type_id == 0)   && !isRequired && (
              <p style={{ fontSize: "12px", color: "red" }}>
                Destination Type is Required
              </p>
            )}
          </div>
        );
      },
      // sorter: (a, b) => a.destination_type_id - b.destination_type_id,
      sorter: {
        compare: (a, b) => a.destination_type_id - b.destination_type_id,
        //multiple: 8,
      },
      hidden: showDestinationType,
    },

      //////////////////////
      {
        title: "Destination Order",
        dataIndex: "destination_order_no",
        width: 100,
        ...getColumnSearchProps("destination_order_no"),
        sorter: {
          // compare: (a, b) => a.destination_order_no.toString().localeCompare(b.destination_order_no.toString()),
          //compare: (a, b) => parseInt(a.destination_order_no) == parseInt(b.destination_order_no),
          compare: (a, b) => a.destination_order_no - b.destination_order_no,
          //multiple: 9,
        },
        defaultSortOrder: "ascend",
        render: (text, record) => {
          const inputHandller = (e) => {
            record.destination_order_no = parseInt(e.target.value);
            if (record.destination_order_no.length > 0) {
              setIsRequired(true);
            }
          };
          return !editablecolumn && !record?.isEditing ? (
            <Input
              type="number"
              className="form-control"
              disabled={!editablecolumn && !record?.isEditing}
              value={parseInt(record.destination_order_no)}
            />
          ) : ( 
            <>
              <Input
                type="number"
                className="form-control"
                defaultValue={parseInt(record?.destination_order_no)}
                onChange={(e) => inputHandller(e)}
                invalid={record?.destination_order_no.length < 1 && !isRequired}
                required
              />
              {record?.destination_order_no.length < 1 && !isRequired && (
                <p style={{ fontSize: "12px", color: "red" }}>
                  Destination Code is Required
                </p>
              )}
            </>
          );
        },
      },  
    //////////////

    //////////////////////
    {
      title: "Destination Code",
      dataIndex: "destination_code",
      width: 100,
      ...getColumnSearchProps("destination_code"),
      sorter: {
        compare: (a, b) => a.destination_code.localeCompare(b.destination_code),
        //multiple: 9,
      },
      //defaultSortOrder: "ascend",
      render: (text, record) => {
        const inputHandller = (e) => {
          record.destination_code = e.target.value;
          if (record.destination_code.length > 0) {
            setIsRequired(true);
          }
        };
        return !editablecolumn && !record?.isEditing ? (
          <Input
            type="text"
            className="form-control"
            disabled={!editablecolumn && !record?.isEditing}
            value={record.destination_code}
          />
        ) : (
          <>
            <Input
              type="text"
              className="form-control"
              defaultValue={record?.destination_code}
              onChange={(e) => inputHandller(e)}
              invalid={record?.destination_code.length < 1 && !isRequired}
              required
            />
            {record?.destination_code.length < 1 && !isRequired && (
              <p style={{ fontSize: "12px", color: "red" }}>
                Destination Code is Required
              </p>
            )}
          </>
        );
      },
    },

    //////////////
    {
      title: "Destination Name",
      dataIndex: "destination_name",
      width: 140,
      ...getColumnSearchProps("destination_name"),    
      sorter: {
        compare: (a, b) => a.destination_name.localeCompare(b.destination_name),
        //multiple: 9,
      },
      //defaultSortOrder: "ascend",
      render: (text, record) => {
        const inputHandller = (e) => {
          record.destination_name = e.target.value;
          if (record.destination_name.length > 0) {
            setIsRequired(true);
          }       
        };
       
        return !editablecolumn && !record?.isEditing ? (
          <Input
            type="text"
            className="form-control"
            disabled={!editablecolumn && !record?.isEditing}
            value={record.destination_name}
          />
        ) : (
          <>
            <Input
              type="text"
              className="form-control"
              defaultValue={record?.destination_name}
              onChange={(e) => inputHandller(e)}
              //onChange={e => { inputHandller(e); onChangeAutoFillDestinationTitle(e) }}

              invalid={record?.destination_name.length < 1 && !isRequired}
              required
            />
            {record?.destination_name.length < 1 && !isRequired && (
              <p style={{ fontSize: "12px", color: "red" }}>
                Destination Name is Required
              </p>
            )}
          </>
        );
      },
    },
    {
      title: "Destination Title (Route)",
      dataIndex: "destination_title",
      width: 140,
      ...getColumnSearchProps("destination_title"),
      sorter: {
        compare: (a, b) => a.destination_title.localeCompare(b.destination_title),
        //multiple: 9,
      },
      //defaultSortOrder: "ascend",
      render: (text, record) => {
        const inputHandller = (e) => {
          //record.destination_title = e.target.value;

          let tempDestinationTitle = e.target.value?.toLowerCase();
          tempDestinationTitle = tempDestinationTitle.replaceAll(' ', '-');
          record.destination_title = tempDestinationTitle;

          if (record.destination_title.length > 0) {
            setIsRequired(true);
          }
        };
        return !editablecolumn && !record?.isEditing ? (
          <Input
            type="text"
            className="form-control"
            disabled={!editablecolumn && !record?.isEditing}
            value={record.destination_title}
          />
        ) : (
          <>
            <Input
              type="text"
              className="form-control"
              defaultValue={record?.destination_title}
              onChange={(e) => inputHandller(e)}
              invalid={record?.destination_title.length < 1 && !isRequired}
              required
            />
            {record?.destination_title.length < 1 && !isRequired && (
              <p style={{ fontSize: "12px", color: "red" }}>
                Destination Title is Required
              </p>
            )}
          </>
        );
      },
    },
    {
      title: "Description",
      width: 240,
      dataIndex: "destination_description",
      ...getColumnSearchProps("destination_description"),
      sorter: {
        compare: (a, b) =>
          a.destination_description.localeCompare(b.destination_description),
        //multiple: 7,
      },
      render: (text, record) => {
        const inputHandller = (e) => {
          record.destination_description = e.target.value;
          if (record.destination_description.length > 0) {
            setIsRequired(true);
          }
        };
        return !editablecolumn && !record?.isEditing ? (
          <Input
            type="text"
            className="form-control"
            disabled={!editablecolumn && !record?.isEditing}
            value={record.destination_description}
          />
        ) : (
          <>
            <Input
              type="text"
              className="form-control"
              defaultValue={record?.destination_description}
              onChange={(e) => inputHandller(e)}
              invalid={
                record?.destination_description.length < 1 && !isRequired
              }
              required
            />
            {record?.destination_description.length < 1 && !isRequired && (
              <p style={{ fontSize: "12px", color: "red" }}>
                Description is Required
              </p>
            )}
          </>
        );
      },
    },
    {
      title: "Image upload (size: 1920x960)",
      width: 100,
      dataIndex: "destination_image",
      render: (text, record) => {
        const onChangeHandller = async (e) => {
          e.preventDefault();
          setImageFormData([...imageFormData, e.target.files[0]]);
          record.destination_image = e.target.files[0].name;
          // try {
          //   const formData = new FormData();
          //   debugger;
          //   formData.append("file", e.target.files[0], e.target.files[0].name);
          //   fetch(`${url}/api/Destination/SaveFile`, {
          //     method: "POST",
          //     body: formData,
          //   })
          //     .then((res) => res.json())
          //     .then((data) => {
          //       record.destination_image = data;
          //       setIsRequired(true);
          //     });

          // } catch (error) {
          //   errornotify(error);
          // }
        };
        return (
          <>
            {record.isEditing || editablecolumn ? (
              <>
                <Input
                  className="form-control"
                  type="file"
                  id="formFile"
                  accept="image/*"
                  onChange={onChangeHandller}
                  invalid={record?.destination_image?.length < 1 && !isRequired}
                />              

                {record?.destination_image.length < 1 && !isRequired && (
                  <p style={{ fontSize: "12px", color: "red" }}>
                    Image is Required
                  </p>
                )}
              </>
            ) : (
              <Image
                src={`${url}/Images/Destination/${record.destination_image}`}
                alt="image"
                height={35}
                width={70}
                style={{ borderRadius: "3px" }}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      width: 80,
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
      // filterMode: "tree",
      onFilter: (value, record) => record.is_active === value,
      render: (text, record) => {
        return (
          <div className="form-check form-switch form-switch-success">
            <Input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="SwitchCheck3"
              onChange={(e) => (record.is_active = e.target.checked)}
              defaultChecked={record.is_active}
              disabled={!editablecolumn && !record?.isEditing}
            />
          </div>
        );
      },
      // sorter: (a, b) => a.is_active - b.is_active,
      sorter: {
        compare: (a, b) => a.is_active - b.is_active,
        //multiple: 6,
      },
    },
    {
      title: "Best Selling",
      dataIndex: "is_best_selling",
      width: 80,
      filter: [
        {
          text: "BestSelling",
          value: true,
        },
        {
          text: "NotBestSelling",
          value: false,
        },
      ],
      onFilter: (value, record) => record.is_best_selling === value,
      render: (text, record) => {
        return (
          <div className="form-check form-switch form-switch-success">
            <Input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="SwitchCheck3"
              onChange={(e) => (record.is_best_selling = e.target.checked)}
              defaultChecked={record.is_best_selling}
              disabled={!editablecolumn && !record?.isEditing}
            />
          </div>
        );
      },
      // sorter: (a, b) => a.is_best_selling - b.is_best_selling,
      sorter: {
        compare: (a, b) => a.is_best_selling - b.is_best_selling,
        //multiple: 5,
      },
    },
    {
      title: "Created By",
      dataIndex: "row_created_by",
      width: 250,
      // sorter: (a, b) => a.row_created_by.localeCompare(b.row_created_by),
      sorter: {
        compare: (a, b) => a.row_created_by.localeCompare(b.row_created_by),
        //multiple: 4,
      },
      hidden: rowCreatedBy,
    },
    {
      title: "Created On",
      width: 160,
      dataIndex: "row_created_date",
      // sorter: (a, b) => a.row_created_date.localeCompare(b.row_created_date),
      hidden: rowCreatedDate,
      sorter: {
        compare: (a, b) => a.row_created_date.localeCompare(b.row_created_date),
        //multiple: 3,
      },
      render: (text, record) => {
        const date = new Date(record.row_created_date);
        return (
          <>
            {date.getDate()}/
            {date.toLocaleString("default", { month: "short" })}/
            {date.getFullYear()}
            {` ${date.getHours()}:${date.getMinutes()}`}
          </>
        );
      },
    },
    {
      title: "Modified By",
      dataIndex: "row_altered_by",
      width: 250,
      // sorter: (a, b) => a.row_altered_by.localeCompare(b.row_altered_by),
      hidden: rowModifiedBy,
      sorter: {
        compare: (a, b) => a.row_altered_by.localeCompare(b.row_altered_by),
        //multiple: 2,
      },
    },
    {
      title: "Modified On",
      dataIndex: "row_altered_date",
      width: 160,
      // sorter: (a, b) => a.row_altered_date.localeCompare(b.row_altered_date),
      hidden: rowModifiedDate,
      sorter: {
        compare: (a, b) => a.row_altered_date.localeCompare(b.row_altered_date),
        //multiple: 1,
      },
      render: (text, record) => {
        const date = new Date(record.row_altered_date);
        return (
          <>
            {date.getDate()}/
            {date.toLocaleString("default", { month: "short" })}/
            {date.getFullYear()}
            {` ${date.getHours()}:${date.getMinutes()}`}
          </>
        );
      },
    },
  ].filter((item) => !item.hidden);

  //console.log(defaultColumns);

  /**This function for add new row */
  const handleAdd = () => {

    debugger;

    var defaultDestinationType  = JSON.parse(localStorage.getItem("global_search_destination_type_option"));
    var destination_type_value  = 0;
    if(defaultDestinationType != null)
      destination_type_value = defaultDestinationType.value;

    let addData = [...dataSource];
    const newData = {
      operation: "Add",
      key: count,
      destination_type_id: destination_type_value,
      destination_order_no: parseInt(dataSource?.length + 2 || 0),
      destination_code: ``,
      destination_name: ``,
      destination_title: ``,
      destination_id: count,
      //destination_type_id: 0,
      is_best_selling: false,
      destination_description: "",
      destination_image: "",
      is_active: isActive,
      row_created_by: userName,
      row_created_date: `${
        date.getMonth() + 1
      }/${date.getDate()}/${date.getFullYear()}`,
      row_altered_by: userName,
      row_altered_date: `${
        date.getMonth() + 1
      }/${date.getDate()}/${date.getFullYear()}`,
      isEditing: true,
      package_count: 0
    };
    addData.splice(0, 0, newData);
    setCount(count + 1);
    setDataSource(addData);
    setShowEdit(false);
    // setEditableColumn(true);
  };
  const handleSave = (row) => {
    //setIsLoading(true);
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);

  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  const onChange = (pagination, filters, sorter, extra) => {};
  /**This is for export table data to excel*/
  useEffect(() => {
    setExportableData(JSON.parse(JSON.stringify(dataSource)));
  }, [
    dataSource,
    showDestinationType,
    rowCreatedBy,
    rowCreatedDate,
    rowModifiedBy,
    rowModifiedDate,
  ]);
  useEffect(() => {
    if (searchText === "") {
      setExportableData(JSON.parse(JSON.stringify(dataSource)));
    }
  }, [searchText]);
  const manageColumnExcel = () => {
    exportTableData.map((item, index) => {
      delete item["key"];
      delete item["operation"];
      delete item["destination_id"];
      delete item["destination_type_id"];
      delete item["isEditing"];
      {
        rowCreatedBy && delete item["row_created_by"];
      }
      {
        rowCreatedDate && delete item["row_created_date"];
      }
      {
        rowModifiedBy && delete item["row_altered_by"];
      }
      {
        rowModifiedDate && delete item["row_altered_date"];
      }
    });
  };
  useEffect(() => {
    if (exportTableData.length > 0) {
      manageColumnExcel();
    }
  }, [exportTableData]);


  const navigateImportDestinations = () => {
    navigate('../importDestinations', {replace: true});
  };


  // const downloadExcel = () => {
  //   const worksheet = XLSX.utils.json_to_sheet(exportTableData);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   XLSX.writeFile(workbook, "DestinationTable.xlsx");
  // };
  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Destinations"
            pageTitle="Setup"
            isSubTitle={true}           
          />
          <Row>
            <Col lg={12}>
              <Card>
                {/* <CardHeader>
                  <h4 className="card-title mb-0 flex-grow-1">
                    Destination Add ,Edit
                  </h4>
                </CardHeader> */}
                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">

                            <div>
                             
                            </div>

                        <div>
                          {showAdd && (
                            <>
                             {isLoading ? (
                                <LoadingButton
                                  btnText={"Sign In"}
                                  externalClass={"success w-100"}
                                  color={"success"}
                                />
                              ) : (
                                <ButttonTravelNinjaz
                                backGroundColor="success"
                                className="add-btn me-1 my-1"
                                id="create-btn"
                                onCLickHancller={handleAdd}
                                buttonIcon={
                                  <i className="ri-add-line align-bottom me-1"></i>
                                }
                                buttonText="Add"
                              />
                              )}
                             
                            </>                            
                          )}
                          {!editablecolumn && showEdit ? (
                            <>
                              <ButttonTravelNinjaz
                                backGroundColor="info"
                                className="add-btn me-1 my-1"
                                id="edit-btn"
                                onCLickHancller={editHandller}
                                buttonIcon={
                                  <i className="ri-edit-line align-bottom me-1"></i>
                                }
                                buttonText="Edit"
                              />
                            </>
                          ) : (
                            <>
                              {/* <Button
                                color="primary"
                                className="add-btn me-1"
                                onClick={saveHandller}
                                id="create-btn"
                              >
                                Save
                              </Button> */}
                              <ButttonTravelNinjaz
                                backGroundColor="primary"
                                className="add-btn me-1 my-1"
                                id="save-btn"
                                onCLickHancller={saveHandller}
                                buttonText="Save"
                              />
                              <ButttonTravelNinjaz
                                backGroundColor="danger"
                                className="add-btn me-1 mx-1 my-1"
                                id="cancel-btn"
                                onCLickHancller={tog_standard}
                                buttonText="Cancel"
                              />
                              {/* <Button
                                color="danger"
                                className="add-btn me-1 mx-1"
                                onClick={tog_standard}
                                id="create-btn"
                              >
                                Cancel
                              </Button> */}
                            </>
                          )}
                          <UncontrolledDropdown
                            style={{ display: "inline-block" }}
                            className="my-1"
                          >
                            <DropdownToggle
                              tag="button"
                              className="btn btn-secondary"
                              type="button"
                            >
                              Export
                            </DropdownToggle>
                            <DropdownMenu>
                              <CSVLink
                                filename={"TravelNinjazB2BDestination.csv"}
                                data={exportTableData}
                                className="btn btn-secondary m-1 mx-2"
                                onClick={() => {
                                  message.success("The file is downloading");
                                }}
                              >
                                Export to CSV
                              </CSVLink>
                              {/* <Button
                                color="secondary"
                                className="add-btn m-1 mx-2"
                                onClick={downloadExcel}
                                id="create-btn"
                              >
                                Export to Excel
                              </Button> */}
                              <ButttonTravelNinjaz
                                backGroundColor="secondary"
                                className="add-btn m-1 mx-1"
                                id="cancel-btn"
                                onCLickHancller={() =>
                                  downloadExcel(
                                    exportTableData,
                                    "DestinationTable"
                                  )
                                }
                                buttonText="Export to Excel"
                              />
                            </DropdownMenu>
                          </UncontrolledDropdown>

                          <ButttonTravelNinjaz
                            backGroundColor="primary"
                            className="btn btn-secondary me-1 mx-1 my-1"
                            id="cancel-btn"
                            onCLickHancller={() =>
                              navigateImportDestinations()
                            }                            
                            buttonText="Import"                            
                          />

                          {/* Show/hide columns */}
                          <UncontrolledDropdown
                            style={{
                              display: "inline-block",
                            }}
                          >
                            <DropdownToggle
                              tag="button"
                              className="btn btn-light mx-1"
                              type="button"
                            >
                              <MoreOutlined style={{ fontSize: "21px" }} />
                            </DropdownToggle>
                            <DropdownMenu>
                            <div className="form-check form-switch form-switch-success mb-3 pl-2 "
                              style={{
                                paddingLeft: "2px !important",                              
                              }}
                              >
                                <Input
                                  className="form-check-input mx-1 pl-2"
                                  type="checkbox"
                                  role="switch"
                                  id="SwitchCheck_destination_type"
                                  defaultChecked={!showDestinationType}
                                  onChange={(e) =>
                                    setShowDestinationType(!e.target.checked)
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="SwitchCheck_destination_type"
                                >
                                 Destination Type
                                </Label>
                              </div>
                              <div className="form-check form-switch form-switch-success mb-3 pl-2">
                                <Input
                                  className="form-check-input mx-1 pl-2"
                                  type="checkbox"
                                  role="switch"
                                  id="SwitchCheck1"
                                  defaultChecked={!rowCreatedBy}
                                  onChange={(e) =>
                                    setRowCreatedBy(!e.target.checked)
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="SwitchCheck1"
                                >
                                  Created By
                                </Label>
                              </div>
                              <div className="form-check form-switch form-switch-success mb-3 pl-2">
                                <Input
                                  className="form-check-input mx-1 pl-2"
                                  type="checkbox"
                                  role="switch"
                                  id="SwitchCheck2"
                                  defaultChecked={!rowCreatedDate}
                                  onChange={(e) =>
                                    setRowCreatedDate(!e.target.checked)
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="SwitchCheck2"
                                >
                                  Created On
                                </Label>
                              </div>
                              <div className="form-check form-switch form-switch-success mb-3 pl-2">
                                <Input
                                  className="form-check-input mx-1 pl-2"
                                  defaultChecked={!rowModifiedBy}
                                  type="checkbox"
                                  role="switch"
                                  id="SwitchCheck3"
                                  onChange={(e) =>
                                    setRowModifiedBy(!e.target.checked)
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="SwitchCheck3"
                                >
                                  Modified By
                                </Label>
                              </div>
                              <div className="form-check form-switch form-switch-success mb-3 pl-2">
                                <Input
                                  className="form-check-input mx-1 pl-2"
                                  type="checkbox"
                                  role="switch"
                                  id="SwitchCheck4"
                                  defaultChecked={!rowModifiedDate}
                                  onChange={(e) =>
                                    setRowModifiedDate(!e.target.checked)
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="SwitchCheck4"
                                >
                                  Modified On
                                </Label>
                              </div>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </Col>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2 my-1">
                            <input
                              type="text"
                              className="form-control search"
                              placeholder="Search..."
                              onChange={(e) => inputHandller(e.target.value)}
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {/* {editablecolumn ? (
                      <Row className="g-4 mb-3 d-xl-none d-sm-none d-md-none">
                        <Col className="col-sm">
                          <Button
                            color="primary"
                            className="add-btn me-1"
                            onClick={saveHandller}
                            id="create-btn"
                          >
                            Save
                          </Button>
                          <Button
                            color="primary"
                            className="add-btn me-1 "
                            onClick={cancelHandller}
                            id="create-btn"
                          >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )} */}
                  </div>
                  <div>
                    {searchText.length > 0 ? (
                      <>
                        <Table
                          // components={components}
                          rowClassName={() => "editable-row"}
                          bordered
                          dataSource={filterData}
                          columns={columns}
                          scroll={{
                            // x: 1000,
                            y: 320,
                          }}
                          ref={tableRef}
                          id="table-to-xls"
                          pagination={{
                            defaultPageSize: 10,
                            showSizeChanger: true,
                            pageSizeOptions: [10, 20, 50, 100],
                            showTotal: (total, range) =>
                              `${range[0]}-${range[1]} of ${total} items`,
                          }}
                          onChange={onChange}
                          locale={{
                            triggerDesc: null,
                            triggerAsc: null,
                            cancelSort: null,
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <Table
                          // components={components}
                          scroll={{
                            // x: 1500,
                            y: 320,
                          }}
                          rowClassName={() => "editable-row"}
                          bordered
                          dataSource={dataSource}
                          columns={columns}
                          pagination={{
                            defaultPageSize: 10,
                            showSizeChanger: true,
                            pageSizeOptions: [10, 20, 50, 100],                          
                            showTotal: (total, range) =>
                              `${range[0]}-${range[1]} of ${total} items  `,                              
                          }}
                          onChange={onChange}
                          locale={{
                            triggerDesc: null,
                            triggerAsc: null,
                            cancelSort: null,
                          }}
                        />
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/**popconfirm modal*/}
      <CancelCofirmModal
        cancelHandller={cancelHandller}
        modal_standard={modal_standard}
        tog_standard={tog_standard}
      />
      {/* <Modal
        id="myModal"
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
        centered
        style={{ width: "280px" }}
      >
        <ModalBody>
          <ConfirmationWarning />
        </ModalBody>
        <div className="modal-footer">
          <ButttonTravelNinjaz
            backGroundColor="danger"
            onCLickHancller={cancelHandller}
            buttonText="Yes"
          />
          <ButttonTravelNinjaz
            backGroundColor="light"
            onCLickHancller={() => {
              tog_standard();
            }}
            buttonText="NO"
          />
        </div>
      </Modal> */}
    </>
  );
};

export default Destination;
