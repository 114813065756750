import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React from "react";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import { Input, Label, Row, Col,  Card, CardBody, CardHeader   } from "reactstrap";
import { url } from "../../services/common/const";
import ButttonTravelNinjaz from "../Common/GloablMaster/ButttonTravelNinjaz";

const PackageImages = ({
  handleAcceptedFiles,
  selectedFiles,
  removeSelectedImages,
  getImageFileNames,
  onImagePrimaryHandller,

  onPrimaryImageDeleteHandller,
  onGalleryImageDeleteHandller,
  toggleArrowTab,
  activeArrowTab,
  modalSaveHandller,
  cancelHandller,

  handleAcceptedPrimaryFile,
  selectedPrimaryFile,
  removeSelectedPrimaryImage,
  getPrimaryImageFileName 
}) => {
  return (
    <>
      {" "}
      <Row>
              <Col lg={12}>
                  <Card>
                    <CardHeader className="align-items-center d-flex bg-light">
                      <h4 className="card-title mb-0">Package Images</h4>
                    </CardHeader>
                    <CardBody>
                      <Row>

                      <div className="cardItems col-6">
                              <Dropzone
                                id="dropZonePrimaryImage"
                                name="dropZonePrimaryImage"
                                onDrop={(acceptedFiles) => {
                                  handleAcceptedPrimaryFile(acceptedFiles);
                                }}
                                accept="image/jpeg,image/png,image/gif,image/jpg"
                                multiple = {false}
                              >
                                {/* {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone dz-clickable dropzonecustom">
                                    <div className="dz-message needsclick" {...getRootProps()} style={{cursor: "pointer"}}>
                                      <div class="text-center material-shadow alert alert-secondary fade show" role="alert">  
                                          Note: Please save the Primary Image in the   <strong> (1155 x 765) </strong>      dimenstion
                                      </div>
                                      <div className="mb-3">
                                        <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                      </div>
                                      <h5>Upload Primary Image.</h5>                                     
                                    </div>
                                  
                                  
                                  </div>
                                  
                                )} */}
                                 {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone dz-clickable dropzonecustom">
                                    <div class="text-center material-shadow alert alert-secondary fade show" role="alert">  
                                    Note: Please save the Primary Image in the   <strong> (1155 x 765) </strong>      dimenstion                         
                                    </div>

                                    <div className="dz-message needsclick" {...getRootProps()}>
                                      <div className="mb-3">
                                        <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                      </div>
                                      <h5>Upload Primary Image.</h5>                                           
                                    </div>  

                                  </div>
                                  
                                )}
                              </Dropzone>
                              <hr />
                              <p>Selected Primary Image</p>
                              {selectedPrimaryFile.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                      <Col xxl={2}>
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </Col>
                                        <Col xxl={9}>
                                          <Link to="#" className="text-muted font-weight-bold">
                                            {f.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                        <Col xxl={1}>
                                          <FeatherIcon
                                            icon="x"
                                            style={{
                                              color: "#364574",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => removeSelectedPrimaryImage(f)}
                                          />
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                );
                              })}    
                              <hr />
                               <div className="list-unstyled mb-0" id="file-previews">
                                <p>Saved Primary Image</p>
                                {/* {getImageFileNames?.filter((elm) => elm.is_primary == 1).map((item, index) => { */}
                                {getPrimaryImageFileName.map((item, index) => {
                                  return (
                                    <Card
                                      className="mt-1 mb-0 shadow-none border d-flex dz-processing dz-image-preview dz-success dz-complete"
                                      key={index + "-file"}
                                    >
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto" xxl={2}>
                                            <img
                                              data-dz-thumbnail=""
                                              height="80"
                                              className="avatar-sm rounded bg-light"
                                              alt={"image"}
                                              src={`${url}/Images/Package/${item.package_id}/${item.image_name}`}
                                            />
                                          </Col>   
                                          <Col xxl={9}>
                                          <Link to="#" className="text-muted font-weight-bold">
                                            {item.image_name}
                                          </Link>
                                            {/* <p className="mb-0">
                                              <strong>{item.formattedSize}</strong>
                                            </p> */}
                                          </Col>

                                          <Col xxl={1}>
                                            <FeatherIcon
                                              icon="trash-2"
                                              style={{
                                                color: "#364574",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => onPrimaryImageDeleteHandller(item)}
                                            />
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  );
                                })}
                              </div>                        
                            </div>
                          <div className="cardItems  col-6">
                              <Dropzone
                                id="dropZoneGalleryImage"
                                name="dropZoneGalleryImage"
                                onDrop={(acceptedFiles) => {
                                  handleAcceptedFiles(acceptedFiles);
                                }}
                                accept="image/jpeg,image/png,image/gif,image/jpg"
                               // multiple = {false}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone dz-clickable dropzonecustom">
                                    <div class="text-center material-shadow alert alert-secondary fade show" role="alert">  
                                        Note: Please save the images in the   <strong>  (765 x 375) </strong> dimenstion                                    
                                    </div>

                                    <div className="dz-message needsclick" {...getRootProps()}>
                                      <div className="mb-3">
                                        <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                      </div>
                                      <h5>Upload Gallery Image.</h5>                                     
                                    </div>  

                                  </div>
                                  
                                )}
                              </Dropzone>
                              <hr />
                              <p>Selected Gallery Images</p>
                              {selectedFiles.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                      <Col xxl={2}>
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </Col>
                                        <Col xxl={9}>
                                          <Link to="#" className="text-muted font-weight-bold">
                                            {f.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                        <Col xxl={1}>
                                          <FeatherIcon
                                            icon="x"
                                            style={{
                                              color: "#364574",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => removeSelectedImages(f)}
                                          />
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                );
                              })}
                              <hr />
                              <div className="list-unstyled mb-0" id="file-previews">
                                <p>Saved Images</p>
                                {/* {getImageFileNames?.filter((elm) => elm.is_primary == 0).map((item, index) => { */}
                                {getImageFileNames?.map((item, index) => {
                                  return (
                                    <Card
                                      className="mt-1 mb-0 shadow-none border d-flex dz-processing dz-image-preview dz-success dz-complete"
                                      key={index + "-file"}
                                    >
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto" xxl={2}>
                                            <img
                                              data-dz-thumbnail=""
                                              height="80"
                                              className="avatar-sm rounded bg-light"
                                              alt={"image"}
                                              src={`${url}/Images/Package/${item.package_id}/${item.image_name}`}
                                            />
                                          </Col>   
                                          <Col xxl={9}>
                                          <Link to="#" className="text-muted font-weight-bold">
                                            {item.image_name}
                                          </Link>
                                            {/* <p className="mb-0">
                                              <strong>{item.formattedSize}</strong>
                                            </p> */}
                                          </Col>

                                          <Col xxl={1}>
                                            <FeatherIcon
                                              icon="trash-2"
                                              style={{
                                                color: "#364574",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => onGalleryImageDeleteHandller(item)}
                                            />
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  );
                                })}
                              </div>
                            </div>
                      </Row>
                    </CardBody>
                  </Card>
              </Col>   
          
      </Row>        


    
     
      <div className="d-flex justify-content-xl-between my-3">
        <div>
          <button
            type="button"
            className="btn btn-light btn-label previestab"
            onClick={() => {
              toggleArrowTab(activeArrowTab - 1);
            }}
          >
            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
            Back
          </button>
        </div>
        <div>
          <ButttonTravelNinjaz
            backGroundColor={"primary"}
            onCLickHancller={modalSaveHandller}
            buttonText={"Save"}
            className="mx-1"
          />
          <ButttonTravelNinjaz
            backGroundColor={"danger"}
            onCLickHancller={cancelHandller}
            buttonText={"Cancel"}
            className="mx-1"
          />
        </div>
      </div>
    </>
  );
};

export default PackageImages;
