export const showDestinationTypeSelectList = localStorage.getItem("global_show_destination_type") == "true" ? true: false
export const showDestinationType =  localStorage.getItem("global_show_destination_type") =="true" ? true: false 


export const showDestinationSelectList = localStorage.getItem("global_show_destination") == "true" ? true: false
export const showDestination = localStorage.getItem("global_show_destination") == "true" ? true: false

export const showCity =  false;


// export const destinationTypeList = localStorage.getItem("global_destinations_type_list") !== "undefined" ?  JSON.parse(localStorage.getItem("global_destinations_type_list")) :  '{}';
// export const destinationList =  localStorage.getItem("global_destinations_list") !== "undefined" ?  JSON.parse(localStorage.getItem("global_destinations_list")) :  '{}';
// export const cityList =   localStorage.getItem("global_city_list") !== "undefined" ?  JSON.parse(localStorage.getItem("global_city_list")) :  '{}';


// export const defaultDestinationType= localStorage.getItem("global_default_destination_type_object") !== "undefined" ?  JSON.parse(localStorage.getItem("global_default_destination_type_object")) :  '{}';
// export const defaultDestination = localStorage.getItem("global_default_destination_object") !== "undefined" ?  JSON.parse(localStorage.getItem("global_default_destination_object")) :  '{}';
// export const defaultCity =  localStorage.getItem("global_default_city_object") !== "undefined" ?  JSON.parse(localStorage.getItem("global_default_city_object")) :  '{}';

// export const defaultCityOption =    localStorage.getItem("global_search_city_option") !== "undefined" ?  JSON.parse(localStorage.getItem("global_search_city_option")) :  '{}';

    


